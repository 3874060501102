/* src/components/Admin/Admin.css */
.admin-container {
  padding: 0px;
  border-radius: 5px;
}
svg {
    min-width: 18px;
}
.admin-container h1 {
  margin-bottom: 20px;
}
.admin-content ul li {
    font-size: 14px !important;
    color: #1a1a1a;
}
.admin-content ul {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    gap: 24px;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeLarge.css-1w8s6so svg {
    width: 24px;
    height: 24px;
}
.admin-container h2 {
  margin-top: 20px;
}
h3.MuiTypography-root.MuiTypography-h6.MuiTypography-gutterBottom.css-4an0mh {
    margin-top: 40px;
    font-size: 24px;
    font-family: 'NexaWebRegular';
    text-transform: lowercase;
}
.admin-content p {
    font-size: 12px;
    color: #bbb;
    margin: 10px auto;

	}
h1.MuiTypography-root.MuiTypography-h5.admin-login-title.css-zq6grw {
    font-family: 'NexaWebRegular';
    text-transform: lowercase;
}
.admin-container input {
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-fvipm8 {
    border-bottom: 1px solid #bbb;
    border-radius: 0;
}
.show__filter .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-fvipm8 {
    border-bottom: 0 solid #bbb;
    border-radius: 0;
}
.admin-content {
    min-height: 35vh;
}
div#mui-component-select-category {
    padding: 6px 0 !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
    height: 57px;
    padding: 0;
    margin: 0 !important;
}
input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
        margin: 0px 0 !important;
    }
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
    border: 0 solid #bbb;
    border-radius: 0;
    padding: 10px 0;
    left: 1059px;
}
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl{
    padding: 10px 0;
    border: 0px solid #bbb;
    border-radius: 0;
}
.admin-container button {
    align-content: center;
    align-items: center;
    background: #000 !important;
    border: none;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 5px;
    position: relative;
    margin: 5px auto;
    width: 100%;
}
.css-n09pap {
    display: inline-flex
;
    border-radius: 4px;
    flex-direction: column;
}
.admin-container form label {
    font-family: 'NexaWebRegular';
    font-size: 18px;
}
.admin-container button:hover {
  background-color: #10059f !important;
}
.admin-container h2 {
    margin-top: 20px;
    font-family: 'NexaWebRegular';
    text-transform: lowercase;
}
.css-1jizp3o {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
    border: 1px solid;
    gap: 0;
    border-radius: 4px;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.MuiBox-root.css-0 {
    width: 100%;
}
.content .MuiContainer-root.MuiContainer-maxWidthLg {
    width: 100%;
    max-width: 100%;
    padding: 0 200px;
}
.admin-container ul {
  list-style-type: none;
  padding: 0;
}

.admin-container li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.admin-container li button {
  background-color: #dc3545;
}

.admin-container li button:hover {
  background-color: #10059f;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper ul li{
    color: #bbb;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper ul li:hover{
    color: #1b1b1b;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper ul {
    display: contents;
    color: #1b1b1b;
    padding: 0;
    margin: 10px auto;
}
.admin-container {
	align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    width: 75%;
    padding-bottom: 100px;
	
}

.admin-sidebar {
    background-color: #ffffff3b;
    padding: 0 15px;
    width: 100%;
}

.admin-sidebar ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.admin-sidebar ul li {
    margin: 20px 0;
    padding: 0 24px 0 0;
}
.admin-content {
    flex-grow: 1;
    padding: 0 16px;
    width: 100%;
}
.admin-content svg path {
    fill: #1b1b1b;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.button button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    width: 40px;
    height: 40px;
    margin: 5px;
    top: 0;
    right: 0;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.text {
    text-align: center;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall svg path:hover {
    fill: #10059f;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall svg path {
    fill: #1b1b1b;
}
.admin-content .MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 0;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary,
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedError.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorError.MuiButton-root.MuiButton-contained.MuiButton-containedError.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorError {
font-size: 10px;
    background: #1b1b1b !important;
    color: #fff;
    height: 40px;
    margin: 10px auto !important;
    width: 100%;
    position: relative;
}
@media (max-width:900px) {
	td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.button button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    height: 50px!important;
    margin: 5px;
    right: 0;
    top: 0;
    width: 50px!important;
}
.admin-container input {
    border-bottom:0px  !important;
    border-radius: 5px;
    margin-right: 10px !important;
    padding: 0 !important;
}
	.admin-container button {
    align-content: center;
    align-items: center;
    background-color: #1b1b1b00;
    border: none;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 48px;
    justify-content: center;
    margin: 0px auto !important;
    padding: 5px !important;
    width: 40px !important;
}
	.admin-sidebar ul {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
.admin-container form label {
        font-family: NexaWebRegular;
        font-size: 16px !important;
        left: -14px !important;
    }
.admin-sidebar ul li {
        height: 40px !important;
        margin: 0px 0 !important;
        padding: 0 24px 0 0 !important;
    }
.admin-content .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth.MuiTextField-root, .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
    width: 100% !important;
}
.admin-content .MuiBox-root {
    display: flex;
    flex-direction: column;
    font-size: 16px !important;
}


div#mui-component-select-category{
border-bottom: 1px solid #1b1b1b;
        border-radius: 0;
        height: auto !important;
        padding: 16px 0;
    }
	
textarea#\:ra\: {
    margin: 16px 0 !important;
    height: auto !important;
    border-bottom: 1px solid #1b1b1b;
    border-radius: 0;
}
	.admin-container h2 {
    margin-top: 20px;
    font-family: 'NexaWebRegular';
    font-weight: 400 !important;
    text-transform: lowercase;
	    font-size: 24px;
        background: #fff;
	}
.admin-content, .admin-sidebar {
        flex-grow: 1;
        padding: 0;
        width: 100%;
        background: #fff;
    }
	.content .MuiContainer-root.MuiContainer-maxWidthLg {
    max-width: 100%;
    padding: 0px;
    width: 100%;
}
.admin-content .MuiFormControl-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
    width: 100% !important;
}
    .admin-container {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        display: block;
    }
.admin-content, .admin-sidebar {
    flex-grow: 1;
    padding: 0 15px;
    width: 100%;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl, .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
    border: 0px solid #bbbbbb00 !important;
    border-radius: 0 !important;
    padding: 0px 0 !important;
}
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
}
.MuiDialogActions-root.MuiDialogActions-spacing.css-1vskg8q {
    right: 12px;
    top: 12px;
}
h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root.css-ohyacs {
    margin: 0px;
    font-family: 'NexaWebRegular';
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 10px 10px;
    flex: 0 0 auto;
    text-transform: lowercase;
}
h6.MuiTypography-root.MuiTypography-h6.MuiTypography-gutterBottom.css-4an0mh {
    margin: 0px;
    font-family: 'NexaWebRegular';
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 10px 10px;
    flex: 0 0 auto;
    text-transform: lowercase;
}
p.MuiTypography-root.MuiTypography-body1.css-9l3uo3 {
    font-family: 'NexaWebRegular';
}

@media (min-width: 290px) and (max-width: 440px) {
	.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
    border: 0 solid #bbb;
    border-radius: 0;
    padding: 10px 0;
    left: 215px !important;
}
}
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.css-1km1ehz {
    background: #eee !important;
    color: #10059f;
    width: 100%;
    position: relative;
}
.MuiBox-root.css-a9kvwp {
    position: relative;
    display: inline-block;
    margin: 2px;
    border-radius: 20px;
}
.MuiBox-root.css-a9kvwp img {
    border-radius: 5px;
}
.admin-content button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1j7qk7u {
    background-color: #fff;
    border-radius: 10%;
    height: 18px;
    width: 18px;
    top: -11px !important;
}
.admin-content button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1j7qk7u svg {
    width: 18px;
}
.admin-content button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1j7qk7u:hover {
    background-color: #ffffff82;
}
.admin-content button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
        background-color: #ffffff !important;
        width: 24px;
        height: 24px;
        display: block;
        position: relative;
        z-index: 999;
        border-radius: 2px;
    }
.admin-content span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.css-zun73v {
    display: none;
}
.admin-content form .MuiBox-root.css-1yuhvjn div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    background: #fff;
}

.admin-container button.MuiButtonBase-root.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-primary.MuiFab-root.MuiFab-circular.MuiFab-sizeLarge.MuiFab-primary.css-1l3umsk {
    align-content: center;
    align-items: center;
    background: #fff !important;
}
.css-1v2gfp5 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.35rem !important;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
.css-2sb4tv {
    width: 80px !important;
    height: 80px !important;
    background-color: rgb(245, 245, 245);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 4px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-1k33q06 path {
    fill: #fff !important;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-1k33q06 {
    stroke: #fff !important;
    fill: #fff !important;
    color: #fff !important;
}
.MuiBox-root.css-1gh5laf img {
    width: 80px !important;
    height: 80px !important;
}