@media (min-width:290px) and (max-width:1024px) {
button.search-button {
    display: none;
}
    button.search-button.mobile {
        display: block;
        height: 40px;
        margin: 0 auto;
        padding: 0px 9px 0 19px;
        position: relative;
        width: 40px;
    }
a.cart-button.active a.cart-button {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
}	
.control__bar__container {
    height: auto;
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 1000;
}

.control {
    width: 24px;
    height: 24px;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;
}

.control:hover {
    color: black;
}
a.cart-button svg {
    width: 24px;
    height: 25px;
}
a.wishlist-button {
    width: 24px;
    height: 24px;
    display: block;
}
button.search-button:hover svg g path {
    color: #10059f;
    fill: #10059f;
}
span.MuiBadge-root.BaseBadge-root.css-1rzb3uu:hover svg path {
    color: #10059f;
    fill: #10059f;
}
.search-results {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 40px auto;
}

@media (min-width:380px) and (max-width: 440px) {
	
a.cart-button, a.cart-button svg {
    height: 18px;
    width: 18px;
}
    button.search-button.mobile {
        display: block;
        height: 40px;
        margin: 0 0 0 7px;
        padding: 0px !important;
        position: relative;
        width: 40px !important;
    }
}
@media (max-width: 1024px) {
	
a.cart-button, a.cart-button svg {
    height: 18px;
    width: 18px;
}
.contorol svg {
    width: 18px !important;
    height: 18px !important;
}
}
.control .wishlist-button.active svg path,
.control .cart-button.active svg path {
    fill: #10059f;
}
@media (max-width: 1920px) {
	
a.cart-button {
    width: 24px;
    height: 25px;
    display: block;
}
.control__bar__container {
    height: auto;
    margin: auto;
    position: relative;
    width: 200px;
    z-index: 1000;
}
.controls__container {
    display: flex;
    align-items: center;
    grid-gap: 22px;
    gap: 24px;
    height: 18px !important;
    justify-content: flex-end;
	}
}
@media (max-width: 1536px) {
	
a.cart-button, a.cart-button svg {
    height: 18px;
    width: 18px;
}
.control__bar__container {
        height: auto;
        margin: auto;
        position: relative;
        width: 162px;
        z-index: 1000;
}
.controls__container {
    grid-gap: 22px;
    gap: 19px;
    height: 18px !important;
}
}