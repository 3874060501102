
.NotFound {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0px auto;
    min-height: 85vh;
    position: relative;
    padding: 0 200px;
}

.NotFound__container {
	height: auto;
    width: 100%;
}
@media (min-width:290px) and (max-width:900px) {
.NotFound {
    padding: 0 16px;
}
}