
.manage__account__container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}


.edit__account__container {
    width: 100%;
    height: 300px;
}

.edit__account {
    width: 250px;
    height: 100%;
}

.edit__account__header {
    width:100%;
    height: 20%;
    font-size: 1.7rem;
    font-weight: bolder;
}

.edit__account__form__container {
    width:100%;
    height: 80%;
}

.edit__account__form {
    width:100%;
    height: 100%;
}

.edit__input__container {
    height:35%;
    width: 100%;
}

.edit__account__input {
    height: 50%;
    width: 100%;
}

.save__changes__button__container {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.save__changes__button {
    height: 60%;
    width: 50%;
    font-size: 1rem;
    background-color: #FFE26E;
    border-color: #FFE26E;
    border-style: dashed;
    font-weight:bold;
}

.separator__line {
    margin-top: 20px;
    height: 5px;
    width: 50%;
    background-color: #FFE26E;
}

/* Delete account form */

.delete_account__container {
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.delete__account {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    flex-direction: column;
}

.delete__account__header {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.7rem;
    font-weight: bolder;
}

.delete__account__prompt {
    height: 35%;
    width: 100%;
}

.delete__account__button__container {
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.delete__account__button {
    height: 45px;
    width: 160px;
    font-size: 1rem;
    background-color: #ff6e6e;
    border-color: #ff6e6e;
    border-style: dashed;
    font-weight:bold;
}