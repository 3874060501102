.product__detail__container {
    display: flex;
    height: 100%;
    min-width: 300px;
    width: 70%;
}
@media (min-width:290px) and (max-width:1024px) {
	.product__detail__container {
    display: flex;
    height: 100%;
    width: 100%;
}
.detail__and__carousel__container {
    flex-wrap: nowrap !important;
    align-items: flex-start !important;
}
.product__main__detail {
	padding: 0px !important;
}
.product__form {
    padding: 0px !important;
}
.item__container .product__detail__container {
    padding: 0 16px;
}
}  
.product__detail__container {
    width: 100%;
    min-width: 300px;
    height: 100%;
    display: flex;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeLarge:hover {
    background-color: #0000;
    padding: 11px;
    width: 48px;
    height: 48px;
}
.product__form {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.product__detail {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.product__name__main {
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    flex-grow: 0;
    font-family: NexaWebRegular;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    order: 0;
    text-transform: lowercase;
    margin-top: 0px;
}
.product__card__action svg.addtocart {
    width: 24px;
    height: 25px;
}
.item__container .detail__and__carousel__container .product__detail__container .product__main__detail .product__detail__description {
    font-size: 14px;
    color: #bbb;
    height: 19px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 0;    
	margin-top: 4px;
    flex-grow: 0;
}

.product__detail__description {
    justify-content: flex-start;
    align-self: flex-start;
    width: 100%;
    font-size: 14px;
    color: #bbb;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeLarge.MuiButton-outlinedSizeLarge.MuiButtonBase-root:hover {
    background: #10059f;
}

.carousel-control-next, a.carousel-control-prev {
    right: 0;
    display: none;
}
.product__color {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50px;
    font-size: 1.2rem;
}

.product-color-label {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.product-color {
    width: 20px;
    height: 20px;
    border-radius: 20px;
}

.product__price__detail {
    display: flex;
    align-items: center;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 24px 0;
}

.product__quantity__and__size {
    width: 50%;
    min-width: 220px;
    height: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product__quantity {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.product__size {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collect__item__actions {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    align-self: flex-start;
}

.add__cart__add__wish {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
	margin: 0;
}

.add__cart {
    width: 100%;
	max-width:100%;
    height: 48px;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
	    margin: 0;
}
.add__wish {
    margin: 0;
}
.add__to__cart__button {
    width: 220px;
    height:45px;
    border-radius: 25px;
    font-size: 0.8rem;
    background-color: #FFE26E;
    border-color: #FFE26E;
    border-style: dashed;
    font-weight:bold;
}

.add__to__cart__button:hover {
    background-color: #e8ce67;
    border-color: #e8ce67;
}

.buy__now {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buy__now__button {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
}

.buy__button {
    width: 220px;
    height:45px;
    border-radius: 25px;
    font-size: 0.8rem;
    background-color: #000000;
    font-weight:400;
    font-family: 'NexaWebRegular';
    border-style: none;
    color: #ffffff;
}

.buy__button:hover {
    background-color: #000000d1;
}

.carousel-indicators [data-bs-target] {
    border-radius: 100% !important;
    box-sizing: initial;
    flex: 0 1 auto;
    padding: 0;
    border: none;
    margin: 0px 16px;    
	transition: background-color 0.6s ease;
}
.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #ddd;
    border-radius: 1px;
    height: 8px;
    width: 8px;
    opacity: 1 !important;
}
.carousel-indicators button.active {
    background-color: #10059f !important;
    font-size: 12px !important;
    width: 10px !important;
    height: 10px !important;
    padding: 1px !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
@media screen and (max-width: 440px) {
	
.related__items__container .related__products .related__card__container .carousel {
    gap: 39px !important;
}
}
@media screen and (max-width: 1024px) {
.related__header h2 {
    width: 50% !important;
    font-size: 24px !important;
    padding: 0px !important;
}
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeLarge {
    border: 1px solid #1b1b1b;
    border-radius: 0;
    padding: 11px;
    width: 48px;
    height: 48px;
}


@media screen and (max-width: 1920px) {
.product__main__detail {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
    gap: 6px;
}
.related__card__container .carousel {
    grid-gap: 24px;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
    justify-content: flex-start;
    overflow-x: auto;
    scroll-behavior: smooth;
}
.product__name__main {
    margin-top: 0px;
}
.related__items__container .related__products .related__card__container .carousel .carousel__item {
flex: 0 0 auto;
    margin-top: 40px;
}
.related__items__container .related__products .related__card__container .carousel {
    gap: 10px;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeLarge.MuiButton-outlinedSizeLarge.MuiButtonBase-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'NexaWebRegular';
    padding: 12px 0;
    grid-gap: 10px;
    gap: 10px;
    height: 48px;    
	font-size: 14px;
    width: 96%;
    background: #1b1b1b;
    border-radius: 0;
    align-content: center;
    flex-wrap: nowrap;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
}
@media screen and (max-width: 1440px) {    
    .related__items__container .related__products .related__card__container .carousel {
        gap: 6px !important;
        width: 100%;
    }
}
@media screen and (max-width: 1536px) {
.product__main__detail {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: 50%;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
}
.related__items__container .related__products .related__card__container .carousel .carousel__item {
    flex: 0 0 auto;
    margin-top: 30px;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeLarge.MuiButton-outlinedSizeLarge.MuiButtonBase-root {
    width: 94%;
}
}