@font-face {
    font-family: 'NexaWebRegular';
    src: url('../asset/fonts/Nexa/nexa_regular.eot');
    src: local('NexaWebRegular'), local('NexaWebRegular'),
         url('../asset/fonts/Nexa/nexa_regular.eot?#iefix') format('embedded-opentype'),
         url('../asset/fonts/Nexa/nexa_regular.woff') format('woff'),
         url('../asset/fonts/Nexa/nexa_regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NexaTextDemo';
    src: url('../asset/fonts/Nexa/NexaTextDemo-Light.eot');
    src: local('NexaTextDemo'), local('NexaTextDemo'),
         url('../asset/fonts/Nexa/NexaTextDemo-Light.eot?#iefix') format('embedded-opentype'),
         url('../asset/fonts/Nexa/NexaTextDemo-Light.woff') format('woff'),
         url('../asset/fonts/Nexa/NexaTextDemo-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
a {
	--font-family-sans-serif: 'NexaWebRegular' !important;
    --font-family-monospace: 'NexaWebRegular' !important;
    font-family: 'NexaWebRegular' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1b1b1b;
    text-decoration: none !important;
    flex: none;
    order: 0;
    flex-grow: 1;
	text-decoration: none;
}
li a:hover {
    color: #bbb;
}
a:hover {
    color: #bbb;
	font-weight: 400;
    text-decoration: none;
}
img {
    height: 100%;
    width: 100%;
    max-height: 100% !important;
}
.slick-slide img {
    display: block;
}
#root {
    max-width: 100% !important;
    margin: 0 auto;
}
.d-flex.min-vh-100 {
    position: relative;
}
ul {
    margin: 0 auto;
}
li {
    font-family: 'NexaWebRegular';
}
hr.MuiDivider-root.MuiDivider-fullWidth {
    border: 0 !important;
}
b, span, a, p, li, ul, input {
  font-weight: normal !important
}
a.wishlist-button:hover > svg path, a.cart-button:hover > svg path {
    fill: #10059f;
    color: #10059f;
}
.category__name span, .category__name p {
    color: #1b1b1b;
}
body {
    margin: 0;
    font-family: 'NexaWebRegular' !important;
	font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    color: #1b1b1b;
}
.product-details p, input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
    font-family: 'NexaWebRegular' !important;
}
@media (max-width: 2560px) {
	
	.new__products__container {
        padding: 0 200px 0;
    }
	.featured__categories__container {
		padding: 0 200px 0px;
	}
	.item-list {
		padding: 0px 200px 0px;
	}
	 .landing__container {
		padding: 0 200px; 
	 }
	.featured__products__container, .header__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0 200px;
    }
	.footer__container {
		padding: 0 198px;
	}  
}
@media (max-width: 1920px) {
	.main-container {
    margin-bottom: 65px;
}
	.product__carousel__container {
    align-items: center;
    height: auto;
    margin: auto;
    width: 100%;
}
	.AboutUs__container span.text {
		font-size: 27px;
	}
	.accordion-header, .accordion-body p, .accordion-body ul li {
    font-size: 18px;
}
    .navbrand__container h1.navbrand svg {
        position: absolute;
        width: 187px;
        height: 26px;
        left: 0;
        top: 9px;
    }
.product__price__detail {
    font-size: 18px;
    margin: 24px 0 0;
}
	.shop__category__container {
    height: auto;
    min-height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
    .category__name span {
        
        font-size: 2.55rem;
        height: 33px !important;
    }
	    .Checkout__container h1, .category__header h2, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 115%;
        letter-spacing: -0.01em;
        text-transform: lowercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #1B1B1B;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .category__name p, li.nav-link span {
        font-size: 18px;
    }
	.product__name a {
        font-size: 18px;
    }

    .product__price span {
        font-size: 18px;
    }
	.new__products__container {
        padding: 0 200px 0;
    }
	.featured__categories__container {
		padding: 0 200px 0px;
	}
	.item-list {
		padding: 0px 200px 0px;
	}
	 .landing__container {
		padding: 0 200px; 
	 }
	.featured__products__container, .header__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0 200px;
    }
	.footer__container {
		padding: 0 198px;
	}  
}

@media (max-width: 1536px) {
	.main-container {
    margin-bottom: 24px;
}
	.slick-slide {
    margin: 0 auto;
    margin-right: 0px !important;
    width: 22rem !important;
}
	.AboutUs__container span.text {
		font-size: 24px;
	}
	.accordion-header, .accordion-body p, .accordion-body ul li {
    font-size: 16px !important;
}
    .navbrand__container h1.navbrand svg {
        position: absolute;
        width: 100%;
        height: 24px;
        left: -16px;
        top: 9px;
    }
.product__price__detail {
    font-size: 16px !important;
    margin: 24px 0 0;
}
	.shop__category__container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
	.new__products {
        margin-bottom: 24px;
    }
    .category__name span {
        font-size: 2.55rem;
    }
	h3.featured__items__header__big, .new__items__header__big, .category__header h2, .shop__category__head h2, .Checkout__container h1, .wishlist__header h2, .related__header h2 {
        font-size: 48px;
        margin: 0 0;
	}
    .category__name p, li.nav-link span {
        font-size: 18px;
    }
	.product__name a {
        font-size: 18px;
    }
	button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
            width: 18px;
        height: 18px;
}
    a.cart-button svg, a.wishlist-button svg, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg, button.search-button svg {
        height: 18px;
        width: 18px;
    }

    .product__name, .product__price {
        font-family: 'NexaWebRegular';
        font-size: 16px !important;
    }
.new__products__container {
    width: 100%;
    margin: 0 0 65px auto;
}
    .product__price span {
        font-size: 16px;
    }
	.new__products__container {
        padding: 0 200px 0;
    }
	.featured__categories__container {
		padding: 0 200px 0px;
	}
	.item-list {
		padding: 0px 199px 0px;
	}
	 .landing__container {
		padding: 0 200px;
        margin: 0px auto !important; 
	 }
.header__container {
        max-width: 100%;
        padding: 0;
    }
a.wishlist-button.active {
    background-size: 18px !important; 
    display: block;
    height: 20px !important; 
    width: 18px !important; 
}
	.featured__products__container, .footer__container, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0 200px;
        margin: 24px auto !important;
    }
}

@media (max-width: 1440px) {
	.main-container {
    margin-bottom: 24px;
}

.header__container {
        max-width: 100%;
        padding: 0;
    }
    .new__products .slick-slide {
        margin: 0 auto;
        margin-right: 0px !important;
        width: 100% !important;
    }
	.featured__products .slick-slide {
        margin: 0 auto;
        margin-right: 0px !important;
        width: 100% !important;
}
.featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin: 0;
        margin-right: 12px;
    }
	.AboutUs__container span.text {
		font-size: 16.8px;
	}
    .navbrand__container h1.navbrand svg {
        position: absolute;
        width: 100% ;
        height: 24px; 
        left: -7px;
        top: 9px;
    }
	ul.fotter__help__links li.help__link a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 4px 0;
    text-transform: lowercase;
}
.footer__contact {
    color: #fff;
    font-size: 16px !important;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
    width: 18px;
}
.product__price__detail {
    font-size: 16px;
    margin: 24px 0 0;
}
	.new__products {
        margin-bottom: 24px;
    }
    .category__name span {
               font-size: 2.2rem;
        height: 23px !important;
    }
	h3.featured__items__header__big, .new__items__header__big, .category__header h2, .shop__category__head h2, .Checkout__container h1, .wishlist__header h2, .related__header h2 {
        font-size: 48px;
		margin: 0 0;
	}
    .category__name p, li.nav-link span {
        font-size: 16px;
    }
	.product__name a {
        font-size: 16px;
    }

    .product__price span {
        font-size: 16px;
    }
	.new__products__container {
        padding: 0 200px 0;
    }
	.item-list {
		padding: 0px 200px 0px;    
		margin: 65px auto 65px;
	}
	.featured__categories__container {
		padding: 0 200px 0px;
	}
	
	.landing__container {
		padding: 0 200px 0px; 
	 }
	.header__container, .footer__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0 200px;
		margin: 0px auto !important;
    }
	.submenu-images a img {
        width: 157px !important;
        height: 172px !important;
    }
}

@media (max-width: 1366px) {
	.main-container {
    margin-bottom: 65px;
}
	.slick-slide {
    margin: 0 auto;
    margin-right: 21px !important;
    width: 22rem !important;
}
    .category__name span {
        font-size: 2rem;
    }
.product__price__detail {
    font-size: 16px;
    margin: 24px 0 0;
}
	h3.featured__items__header__big, .new__items__header__big, .category__header h2, .shop__category__head h2, .Checko, .wishlistut__container h1, .wishlist__header h2, .related__header h2 {
        font-size: 48px;
		margin: 0 0;
	}
    .category__name p, li.nav-link span {
        font-size: 16px;
    }
	.product__name a {
        font-size: 16px;
    }

    .product__price span {
        font-size: 16px;
    }
	.new__products__container {
        padding: 0 100px 0;
    }
	.featured__categories__container {
		padding: 0 100px 20px;
	}
	.item-list {
		padding: 0px 100px 0px !important;
	}
	.landing__container {
		padding: 0 100px !important; 
	 }
	.featured__products__container, .header__container, .footer__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0 100px;
		margin: 0px auto !important;
    }
	.submenu-images a img {
        width: 153px !important;
        height: 172px !important;
    }
}

@media (max-width: 1228.8px) {
	.main-container {
    margin-bottom: 65px;
}
	.carousel__image {
       min-width: 100%;
       height: 528px;
       margin: 0;
   }
.product__price__detail {
    font-size: 16px;
    margin: 24px 0 0;
}
    .category__name span {
        font-size: 1.8rem;
    }
	h3.featured__items__header__big, .new__items__header__big, .category__header h2, .shop__category__head h2, .Checkout__container h1, .wishlist__header h2, .related__header h2 {
        font-size: 48px;
		margin: 0 0;
	}
    .category__name p, li.nav-link span {
        font-size: 16px;
    }
	.product__name a {
        font-size: 16px;
    }

    .product__price span {
        font-size: 16px;
    }
	.new__products__container {
        padding: 0 16px 0;
    }
	.featured__categories__container {
		padding: 0 16px 0px;
	}
	.item-list {
		padding: 0px 16px 0px !important;
	}
	.featured__products__container, .header__container, .footer__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding:0 16px !important;
    }
	.submenu-images a img {
        width: 153px !important;
        height: 172px !important;
    }
}
@media (max-width: 1024px) {
	.item__container {
    height: auto;
    padding: 0 200px;
    width: 100%;
}
	.main-container {
    margin-bottom: 29px;
}
    .navbrand__container h1.navbrand svg {
        height: 24px;
        left: 0 !important;
        position: relative;
        top: 0px !important;
        width: 100% !important;
    }
	.AboutUs__container span.text {
		font-size: 18px;
	}
    .category__name span {
        font-size: 1.7rem;
    }
	h3.featured__items__header__big, .new__items__header__big, .category__header h2, .shop__category__head h2, .Checkout__container h1, .wishlist__header h2, .related__header h2 {
        font-size: 48px;
		margin: 0 0;
	}
    .category__name p, li.nav-link span {
        font-size: 16px;
    }
	.product__name a {
        font-size: 16px;
    }
	.category__card__card {
        width: 48%;
	}
    .product__price span {
        font-size: 16px;
    }
	.new__products__container {
        padding: 0 16px 0;
    }
	.featured__categories__container {
		padding: 0 16px 0px;
	}
	.item-list {
		padding: 0px 16px 0px;
	}
	.landing__container {
		padding: 0 !important; 
	 }
	.featured__products__container, .header__container, .footer__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0 24px;
    }
	.submenu-images a img {
        width: 153px !important;
        height: 172px !important;
    }
	.min-vh-100 {
        min-height: 100% !important;
    }
	a.wishlist-button.active {
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 0 1 3.47 1.404A4.732 4.732 0 0 1 18 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 0 1-4.342 2.952.501.501 0 0 1-.464 0 27.41 27.41 0 0 1-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 0 1 1.44-3.383A4.98 4.98 0 0 1 4.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 18px;
    height: 18px;
}
*, ::after, ::before {
    box-sizing: border-box;
    overflow: initial;
}
.featured__categories .slick-dots li.slick-active button:before {
        color: #10059f !important;
        opacity: 1;
        font-size: 12px !important;
    }
.featured__categories .slick-dots li button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
    content: "•";
    font-family: slick;
    font-size: 8px !important;
    height: 20px;
    left: 0;
    line-height: 20px;
    opacity: .75 !important;
    font-size: 8px !important;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}
}
@media (max-width: 744px) {
    .featured__products .slick-slide, .new__products .slick-slide {
        margin: 0 auto;
        margin-right: 0 !important;
        width: 100% !important;
        min-width: 50%;
    }
	
}
@media (max-width: 768px) {
	.accordion-header, .accordion-body p, .accordion-body ul li {
    font-size: 14px !important;
}
	    .featured__products__container {
        margin: -24px 0 auto !important;
        max-height: 100% !important;
    }
    .main-container {
        margin-bottom: 24px !important;
    }    
	.category__name span {
        font-size: 40px;
    }

}

@media (max-width: 440px) {
	.item-list {
        margin: 40px auto 40px !important;
    }
}
@media (max-width: 440px) {
	    .featured__products__container {
        margin: 40px 0 auto !important;
    }
	.featured__products__container, .header__container, .footer__container, .category__container, .submenu-content, .shop__contianer, .Checkout, .wishlist, .DeliveryAndPayment{
        padding: 0px 16px !important;
    }
	.featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin: 0;
        margin-right: 0px !important;
    }
    .main-container {
        margin-bottom: 0px !important;
    }    
.custom-carousel-item {
        position: relative;
        top: 0px;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
.product__carousel .carousel.slide.carousel-dark .carousel-inner .carousel-item {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: none;
        float: left;
        margin-right: -100%;
        opacity: 0;
        position: relative;
        transition: transform .5s ease-in-out, opacity .8s ease-in-out;
        width: 100%;
}

.product__carousel .carousel.slide.carousel-dark .carousel-inner .carousel-item.active {
    display: block; /* Активный слайд отображается */
        opacity: 1;
    transform: translateX(0);
 /* Полная непрозрачность */
}
     .product__carousel .custom-carousel-item {
        position: relative;
        top: 0;
        width: 100%;
        z-index: 1;
    }
	.custom-carousel-item.active.carousel-item {
    z-index: 2;
}
	.category__name span {
        font-size: 40px;
    }

}
/* Базовый стиль для плашки */
.message-box {
        display: none;
        position: fixed;
        height: 100vh;
        text-align: center;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        line-height: 150%;
        margin: 10px auto;
        gap: 14px;
    }

/* Показывать плашку при необходимости */
.message-box.show {
    display: flex;
    z-index: 99999;
    position: relative;
    top: -70px;
    height: 100vh;
    background: #fff;
}
.message-box.show {
    font-size: 12px;
    padding: 0 6px;
}
.message-box.show svg {
    width: 100%;
}
/* Скрываем основной контент при необходимости */
.main-container.hidden {
    display: none;
}
/* Стили для спиннера загрузки */
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Для полноэкранного спиннера (если нужно) */
.fullscreen-spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1000;
}

/* Адаптация основного контейнера */
.main-container {
    transition: opacity 0.3s ease;
}

.main-container.hidden {
    opacity: 0;
    pointer-events: none;
}

.message-box {
    display: none;
    padding: 1rem;
    text-align: center;
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;
}

.message-box.show {
    display: block;
}