.header__container {
    height: auto;
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: none;
    position: sticky;
    top: 0;    
    z-index: 1000;
    border-bottom: 1px solid #dfdfdf;
}