.shop__contianer {
    height: auto;
    min-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.product__card__action {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
    position: absolute;
    top:12px;
    right: 12px;
    z-index: 3;
}
.related__product__card__inner .product__card__action {
-webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: center;
    margin-bottom: .83rem;
    margin: 0;
    position: absolute;
    top: 12px ;
    width: 24px;
    z-index: 3;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    box-shadow: none;
        float: right;
        left: 0 !important;
        position: relative !important;
        right: 0 !important;
        width: 200px !important;
}
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    background-color: #fff0 !important;
}
@media (min-width:290px) and (max-width: 1024px) {
	
label.maxPrice {
    position: absolute !important;
    margin-right: 10px !important;
    display: block !important;
    padding-left: 10px !important;
    left: auto !important;
    margin: 12px auto !important;
}
}
@media (max-width: 440px) {
	.shop__container {
    overflow: hidden;
}
li.search-result-item .product-details .product__card__action {
        align-content: flex-end;
        align-items: flex-end;
        right: 0px !important;
        top: -300px !important;
    }
    .product__card__action {
        align-content: flex-end;
        align-items: flex-end;
        right: 12px !important;
        top: 3px !important;
    }
}
@media (max-width: 1536px) {
.related__product__card__inner .product__card__action {
-webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: center;
    margin-bottom: .83rem;
    margin: 0;
    position: absolute;
    top: 12px !important;
    width: 24px;
    z-index: 3;
}
}
@media (max-width: 1024px) {
	.product__card__action {
    align-content: flex-end;
    align-items: flex-end;
    right: 24px;
    top:14px;
}
.shop__container {
    padding: 0 16px;
}
    .MuiDialogContent-root .filter-section {
        width: auto;
        margin: 0 auto;
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
left: auto !important;
    position: relative !important;
    right: 199px !important;
    top: 206px !important;
    width: 208px !important;
}