@media (min-width: 430px) and (max-width: 440px) { 
    .product__carousel__container {
        align-content: flex-start;
        align-items: center;
        align-items: flex-start;
        display: flex;
        height: 548px;
        margin: 0;
        min-width: 56% !important;
        padding: 0 16px;
        width: 100%;
    }
}
.product__carousel {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
}

.carousel__image:hover {
    transform: scale(1.1);
}
.carousel {
    grid-gap: 24px;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
    justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
}
.carousel__item .related__product__card__container .related__product__card__inner .related__product__card__detail .product__card__action {
    width: 40px;
    height: 40px;
    position: relative;
    top: -391px;
    right: -96px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 5px;
    align-content: center;
    align-items: center;
    z-index: 2;
}
.carousel__image__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}
@media screen and (max-width: 1920px) {
    .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 65px;
        width: 32.2% !important;
    }
.slick-slide {
display: block !important;
        float: left;
        height: 100%;
        min-height: 1px;
}
.featured__categories .slick-slide {
    display: block !important;
    float: left;
    height: 100%;
    min-height: 1px;
}
.category__image a>img {
    height: auto;
    transition: 1s;
}
.slick-track:after, .slick-track:before {
    display: none !important;
}
.product__carousel__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product__carousel {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.carousel__image__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.carousel__image {
    width: 100%;
    height: auto;
	cursor: zoom-in !important;
    transition: transform 0.5s ease-in-out;
}

.thumbnail__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.thumbnail {
    width: 60px;
    height: 60px;
    margin: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.thumbnail.active {
    transform: scale(1.2);
}
}
@media screen and (max-width: 1706.67px) { 
.featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 24px;
        width:31.88889%!important;
    }
}
@media screen and (max-width: 1536px) {   

 .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        width: 100%
    }
.slick-slide {
    display: block !important;
    float: left;
    height: 100%;
    min-height: 1px;
    width: 100%!important;
}
.featured__categories .slick-slide {
    display: block !important;
    float: left;
    height: 100%;
    min-height: 1px;
    width: 100%!important;
}
.category__image a>img {
    height: auto;
    transition: 1s;
}
}
@media (min-width:440) and (max-width: 744px) {
.featured__categories__container .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 24px;
        margin-right: 0 !important;
        padding: 0;
        width: 100%!important;
        min-width: 50%!important;
    }
	}
@media screen and (max-width: 1440px) {
	.featured__categories__container .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 24px;
        width: 31.999991%!important;
        padding: 0;
		margin-right:0px !important;
    }
.featured__categories .slick-slide {
        display: block !important;
        float: left;
        height: 100%;
        min-height: 1px;
        width: 100% !important;
		        margin-right: 12px !important;
    }
	.category__image a>img {
    height: auto;
    transition: 1s;
}
}
.featured__categories .slick-slider .slick-list .slick-track {
    gap: 17px;
}
.featured__categories .slick-slider .slick-list .slick-track .slick-slide:nth-child(-n+3) {
    margin-bottom: 24px;
}
@media screen and (max-width: 1024px) {

.product__carousel {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
.featured__categories .slick-slide {
        display: block !important;
        float: left;
        height: 100%;
        min-height: 1px;
        width: 100% !important;
    }
	.featured__categories .category__image a>img {
    height: auto;
    transition: 1s;
}
}
@media screen and (max-width: 1024px) {
	.carousel__image__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
    .carousel__image {
        max-width: 100% !important;
		min-width: 100% !important;
        height: auto !important;
        margin: 0;
    }
.carousel-inner {
        overflow: hidden;
        position: relative;
        width: 100%;
    }
	.carousel {
    grid-gap: 22px;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 22px;
    height: 100%!important;
    justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
}
	.product__detail__container {
    width: 100% !important;
    min-width: 300px;
    height: 100% !important;
    display: flex;
}
.carousel-indicators {
        bottom: 0 !important;
        height: 40px;
        margin-bottom: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .product__carousel__container {
        width: 100%;
        margin: 0 0 24px auto;
        padding: 0 16px;
    }
    .carousel__image__container{
               align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width:99%;
        padding: 0px;
    }
}

.product__carousel__container {
grid-gap: 24px;
    gap: 24px;
    display: flex;
    flex-direction: row-reverse;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0;
    top: 0;
    margin: 0;
}

.product__carousel {
    flex: 1;
}

.thumbnail__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.thumbnail.active {
    opacity: 1; /* Подсвечиваем активную миниатюру */
}

.thumbnail:hover {
    opacity: 0.8; /* Уменьшаем непрозрачность при наведении */
}

		.detail__and__carousel__container .product__carousel__container .product__carousel .carousel-indicators {
    display:none;
    margin-bottom: 0;    
	bottom: -50px;
}

@media (max-width: 1920px) {
.product__carousel .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container .carousel__image {
        margin: 0;
        width: 100%;
    }
	.carousel-indicators {
    display: none !important;
}
.thumbnail {
    width: 105px; /* Измените на необходимую ширину */
    height: auto;
    cursor: pointer;
    margin-bottom: 0px;
    opacity: 0.6;
    transition: opacity 0.3s;
	width: 105px;
    max-height: 136px;
}
.product__carousel__container {
        align-items: flex-start;
        height: auto;
        margin: 0;
        display: flex;
        width: 100%;
        align-content: flex-start;
    }
	.detail__and__carousel__container .product__carousel__container .product__carousel .carousel-indicators {
    bottom: -50px;
    display: none;
    margin-bottom: 0;
}
}

@media (max-width: 1440px) {
	.product__carousel__container {
    align-items: center;
    height: auto;
    margin: 0;
    min-width: 56% !important;
    width: 100%;
}    
.product__carousel__container {
        align-content: flex-start;
        align-items: center;
        align-items: flex-start;
        display: flex;
        height: auto;
        margin: 0;
        padding: 0 16px;
        width: 100%;
    }
.featured__categories .slick-slider .slick-list .slick-track {
    gap: 19px !important;
}
}
@media (max-width: 1536px) {
	.product__carousel .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container .carousel__image {
        margin: 0;
        width: 100%;
    }
    .thumbnail {
        cursor: pointer;
        height: auto;
        margin-bottom: 0;
        max-height: 136px;
        opacity: .6;
        transition: opacity .3s;
        width: 65px;
        object-fit: contain !important;
    }
    .carousel__image {
        height: 100%;
        object-fit: cover !important;
        min-height: 549px;
    }

	.slick-slide {
    display: block !important;
    float: left;
    height: 100%;
    min-height: 1px;
    width:100%!important;
}	

.carousel__image__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}
.featured__categories .slick-slider .slick-list .slick-track {
    gap: 20px;
}
.slick-track:after, .slick-track:before {display:none !important;}
	}
	@media (max-width: 1366px) {
		    .featured__categories .slick-slider .slick-list .slick-track {
        gap: 16px !important;
    }
		.carousel-indicators {
    display: block !important;
}
		    .carousel__image__container {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
    }
		.detail__and__carousel__container .product__carousel__container .product__carousel .carousel-indicators {
    display:flex !important;
    margin-bottom: 0;
}
    .featured__categories .slick-slider .slick-list .slick-track {
        align-content: center !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        gap:0px !important;
        justify-content: flex-start !important;
        padding: 0;
        width: 100% !important;
    }
    .slick-slide {
        display: block !important;
        float: left;
        height: 100%;
        width: 100% !important;
}
	}

	@media (min-width:375) and (max-width: 440px) {
		.product__carousel .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container .carousel__image {
        height: 411x;
        margin: 0;
        width: 100% !important;
    }    

	.carousel__image__container {
        align-content: flex-start !important;
        align-items: flex-start;
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        height: 100%;
        justify-content: center !important;
        margin: 0 !important;
        max-width: 100% !important;
        padding: 0 !important;
        width: 100% !important;
    }
	.featured__categories__container .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 56px;
        padding: 0;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
	}
	@media (max-width: 375px) {
		.product__carousel .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container .carousel__image {
        height: 411x;
        margin: 0;
        width: 100% !important;
    }    
	.carousel__image__container {
        align-content: flex-start !important;
        align-items: flex-start;
        display: flex;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        height: 100%;
        justify-content: center !important;
        margin: 0 !important;
        max-width: 100% !important;
        padding: 0 !important;
        width: 100% !important;
    }
	.featured__categories__container .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 24px;
        padding: 0;
        width: 100% !important;
        max-width: 100%!important;
        min-width: 100%!important;
    }
    .carousel__image {
        margin: 0;
        width: 100%;
        height: auto;
        margin: 0;
        width: 100%;
        object-fit: contain !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
	
	} 
	.modal-content {
    top: 0px;
    position: absolute !important;
	}