
@media (min-width:290px) and (max-width:900px) {
.Accordion span {
    margin: 40px 0 24px auto !important;
    height: 30px !important;
    font-size: 24px !important;
}
.ReturnsAndExchanges__container {
    margin-bottom: 24px !important;
}
.ReturnsAndExchanges__container nav.breadcrumbs {
    display: none;
}
.Accordion h1 {
height: 54px !important;
}

.ReturnsAndExchanges {
    height: auto;
    width: 100%;
    padding: 0 16px;
}
.ReturnsAndExchanges {
    height: auto;
    width: 100%;
    padding: 0 16px !important;
}
}

.ReturnsAndExchanges {
    height: auto;
    width: 100%;
    padding: 0 200px;
}
.ReturnsAndExchanges {
    min-height: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    align-content: flex-start;
}

.Accordion__content p {
    font-size: 16px !important;
}
.ReturnsAndExchanges__container {
	height: auto;
    width: 100%;
}
nav.breadcrumbs {
    margin: 0 0 40px 0;
}
.Accordion {
    max-width: 858px;
    margin: 0 auto;
}
.Accordion h1:first-of-type {
    margin: 0;
}
.Accordion h1 {
    margin: 0 auto;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    order: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px;
    gap: 10px;
	width: 100%;
    max-width: 858px;
    height: 75px;
    border-top: 1px solid #DFDFDF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.Accordion span {
    margin: 0 0 40px auto;
    display: block;
	width: 100%;
    max-width: 858px;
    height: 74px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 115%;
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.Accordion__content p {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
}
.ReturnsAndExchanges__container {
    margin-bottom: 120px;
}
.Accordion__content {
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: 0;
    opacity: 0;
}

.Accordion__content.open {
    max-height: 500px; 
    opacity: 1; 
    padding-bottom: 24px;
}

.Accordion__content p {
    margin: 0;
}
@media (max-width: 1920px) {
	.Accordion__content p {
    font-size: 18px !important;
}
}
@media (max-width: 600px) {
    .ReturnsAndExchanges {
        padding: 0 16px;
    }

    .Accordion h1 {
        font-size: 18px;
        padding: 12px;
    }

    .Accordion__content {
        padding: 8px 10px;
    }
}
