
.search__container {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__container__header {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}