@media (min-width: 440px) and (max-width: 1024px) {
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    width: 150px !important;
}
.wishlist__item__label, .wishlist__items {
    grid-template-columns: repeat(auto-fill, minmax(166px, 1fr)) !important;
    justify-items: center;
}
.wish__card, .wishlist__items .wish__card__container, .wish__item__image{
    width: 196px !important;
}
.wish__item__image .product__wish__action {
        top: 35px !important;
		        left: 152px !important;
    }
    .wish__item__details .product__wish__action {
        top: -270px !important;
        right: -143px !important;
    }
	.product__wish__action button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    height: 20px;
}
.wish__item__add__to__cart button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary {
    width: 24px;
    justify-content: center;
}
.wish__item__image, .wishcard {
    align-items: center;
    display: flex;
    height: auto;
    flex-direction: column;
    flex-wrap: nowrap; 
    align-content: center;
    justify-content: center;
}
}
@media (min-width: 430px) and (max-width: 440px) {
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    width: 150px !important;
}
.wishlist__item__label, .wishlist__items {
    grid-template-columns: repeat(auto-fill, minmax(166px, 1fr)) !important;
    justify-items: center;
}
.wish__card, .wishlist__items .wish__card__container, .wish__item__image{
    width: 196px !important;
}
.wish__item__image .product__wish__action {
        top: 35px !important;
		        left: 152px !important;
    }
    .wish__item__details .product__wish__action {
        top: -270px !important;
        right: -143px !important;
    }
	.product__wish__action button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    height: 20px;
}
.wish__item__add__to__cart button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary {
    width: 24px;
    justify-content: center;
}
.wish__item__image, .wishcard {
    align-items: center;
    display: flex;
    height: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}
}
.wishcard {
    width: 100%;
    min-height: 100px;
    height: auto;
    display: block;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: 0px auto;
}

.wish__item__image {
    display: flex;
    justify-content: center;
    width: 362px;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.wish__image {
    height: auto;
    width: 100%;
    min-height: 254px !important;
}

.wish__item__name {
    width: 120px;
    height: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wish__item__price {
    width: 50px;
    height: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add__to__cart {
    width: auto;
    height: 100%;
}

.wish__remove__item__icon {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product__wish__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 58px;
        justify-content: center;
        left: 200px;
        padding: 0px;
        position: relative;
        top: 58px;
        width: 24px;
}
.wish__item__details {
    width: 100%;
    text-align: left;
}
.wish__item__price {
    height: 28%;
    font-size: 16px;
    font-family: NexaWebRegular;
    font-weight: 400;
    text-transform: lowercase;
    display: block;
}
.css-1yxmbwk:hover {
    background-color: #fff0;
}
.wish__item__details .wish__item__name, .wish__item__details .wish__item__price {
    display: block;
    font-family: NexaWebRegular;
    font-size: 16px;
    font-weight: 400;
    height: 28%;
    text-transform: lowercase;
    width: 100%;
    color: #1b1b1b;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root.css-79xub {
    background: #fff0;
    border: none;
    display: flex;
    justify-content: flex-start;
    width: 208px;
	    color: #1b1b1b !important;
}
.show__filter button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-79xub
{
	color: #1b1b1b;
	left: 0 !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-79xub {
    left: 0;
    margin: 0;
    min-width: 100px;
    padding: 0;
    right: 0 !important;
    width: 100px;
}
.wish__item__add__to__cart {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 48px;
    height: 24px;
}
.wish__remove__icon {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.wish__remove__icon button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1yxmbwk {
    right: 0;
    top: 0;
}

@media screen and (max-width: 1920px) {
    .wish__remove__icon button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 0 1 3.47 1.404A4.732 4.732 0 0 1 18 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 0 1-4.342 2.952.501.501 0 0 1-.464 0 27.41 27.41 0 0 1-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 0 1 1.44-3.383A4.98 4.98 0 0 1 4.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: contain;
    }
.checkout button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-79xub {
    
    width:100% !important;
    text-align: center;
    margin: 0 auto;
    right: 0 !important;
    left: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
	color: #fff !important;
}
.wish__card__container {
    width: 100%;
}
    .wish__item__image {
        justify-content: center;
        width: 100%;
    }
.wishlist__item__label, .wishlist__items {
    align-content: center;
    align-items: center;
    display: grid;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-template-columns: repeat(auto-fill, minmax(362px, 1fr));
    justify-content: flex-start;
    width: 100%;
}
.product__wish__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 58px;
        justify-content: center;
        left: 337px;
        padding: 0;
        position: relative;
        top: 71px;
        width: 24px;
        z-index: 9;
    }
	.wishlist__items svg.addtocart {
    height: 24px;
    width: 24px;
}
.wishlist__item__label, .wishlist__items {
        grid-gap: 24px;
        align-content: center;
        align-items: center;
        display: grid;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 6px;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        justify-content: flex-start;
        width: 100%;
    }
.wish__card__container {
    width: 25.377778%!important;
}
.wish__image {
    height: auto;
    width: 100%;
    min-height: 340px !important;
}
}
@media screen and (max-width: 1536px) {
.wish__item__add__to__cart svg.addtocart {
    width: 18px !important;
    height: 18px !important;
}
    .wish__item__add__to__cart button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary {
        left: 3px;
        padding-top: 12px;
        width: 18px !important;
    }
    .wish__card__container {
        width: 24.277778%!important;
    }
    .wish__image {
        height: auto;
        min-height: 380px!important;
        width: 100%;
    }
.wish__item__image {
    justify-content: center;
    width: 100% !important;
}
.product__wish__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 0;
        justify-content: center;
        left: 243px;
        padding: 0;
        position: relative;
        top: 38px;
        width: 24px;
        z-index: 9;
    }
	
.wish__card a.wish__item__image .product__image:hover {
	opacity:.9;
}
.wish__card a.wish__item__image .product__image {
    position: relative;
    width: 100%;
    overflow: hidden;    
	cursor: pointer;
}

.wish__card a.wish__item__image .product__image .product__img {
    width: 100%;
    position: absolute;
    top: 0;
    transition: transform 0.5s ease-in-out, opacity 0.8s ease-in-out;
    opacity: 0;
}

.wish__card a.wish__item__image .product__image .product__img.active {
    transform: translateX(0);
    opacity: 1;
}

.wish__card a.wish__item__image .product__image .product__img.left {
    transform: translateX(-100%);
    opacity: 0;
}

.wish__card a.wish__item__image .product__image .product__img.right {
    transform: translateX(100%);
    opacity: 0;
}
.wishlist__item__label, .wishlist__items {
    align-content: center;
    align-items: center;
    display: grid;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-template-columns: repeat(auto-fill, minmax(242px, 1fr)) !important;
    justify-content: flex-start;
    width: 100%;
    gap: 6px;
}

}
@media screen and (max-width: 1440px) {
    .wish__card__container {
        width: 24.277778%!important;
    }
.wish__item__image {
    justify-content: center;
    width: 100% !important;
}
.product__wish__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 0;
        justify-content: center;
        left: 206px;
        padding: 0;
        position: relative;
        top: 36px;
        width: 24px;
   
    }
.wishlist__item__label, .wishlist__items {
    align-content: center;
    align-items: center;
    display: grid;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-template-columns: repeat(auto-fill, minmax(242px, 1fr)) !important;
    justify-content: flex-start;
    width: 100%;
    gap: 6px;
}

}
@media screen and (max-width: 744px) {
	.wish__card, .wish__item__image, .wishlist__items .wish__card__container {
    }
	.wish__card .product__image>img {
        height: 100%;
        min-height: 440px;
        object-fit: cover !important;
    }
	    .wish__item__image .product__wish__action {
        top: 35px !important;
    }
}
@media screen and (max-width: 440px) {
	    .wish__card, .wish__item__image, .wishlist__items .wish__card__container {
    }
	    .wish__card .product__image>img {
        height: 100%;
        min-height: 100%;
        object-fit: cover !important;
    }
	.product__wish__action {
        left: 201px !important;
}
.product__wish__action {
        top: 39px !important;
    }
	.wish__image {
    height: auto;
    width: 100% !important;
}
.wish__card__container, .wish__item__image {
        width: 100% !important;
    }
	    .wishlist__items svg.addtocart {
        height: 18px !important;
        width: 18px !important;
    }
.wish__item__image {
    justify-content: center;
    width: 100% !important;
}
    .wish__card__container {
        width: 100% !important;
}
    .wishlist__item__label, .wishlist__items {
        gap: 0px !important;
    }    
	.product__wish__action {
        left: 152px!important;
	}
	    .wishlist__items svg.addtocart {
        height: 18px !important;
        width: 18px !important;
    }
	button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-79xub {
        left: 0px !important;
    }
	.wish__card__container {
    margin-top: 0px !important;
    width: 100% !important;
}
}
@media screen and (max-width: 400px) {
	
	.product__wish__action {
        left: 141px!important;
	}
    .product__wish__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 0;
        justify-content: center;
        left: 220px;
        padding: 0;
        position: relative;
        top: 40px;
        width: 24px;
    }
}
@media screen and (max-width: 1024px) {
.wish__card__container {
    width:100% !important;
}
.wish__item__image {
    justify-content: center;
    width: 100% !important;
}
    .wishlist__item__label, .wishlist__items {
        grid-gap: 11px;
        align-content: center;
        align-items: center;
        display: grid;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 11px;
        grid-template-columns: repeat(auto-fill, minmax(166px, 1fr)) !important;
        justify-content: flex-start;
        width: 100%;
    }
}