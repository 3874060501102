
@media (min-width:290px) and (max-width:1366px) {
	.item-list {
    grid-gap: 0;
    align-content: space-between;
    align-items: stretch;
    display: flex;
    display: block;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
    height: auto;
    justify-content: flex-start;
    margin: 22px 0 34px auto;
    position: relative;
    width: 100%;
    padding: 0 16px;
}
a.item-link span.item-name {
    font-size: 24px !important;
}
}
/* ItemList.css */
.item-list {
    display: flex;
    grid-gap: 0;
    gap: 0;
    width: 100%;
    height: auto;
    margin: 22px 0 34px auto;
    align-content: space-between;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: column;
    position: relative;
    display: block;
}

.item:hover .item-name {
    color: #10059f;
    background: none; /* Если это нужно, иначе можно удалить */
}

.item:hover .arrow {
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 12C2.90038e-07 5.36471 5.36471 -2.90038e-07 12 0C18.6353 2.90038e-07 24 5.36471 24 12C24 18.6353 18.6353 24 12 24C5.36471 24 -2.90038e-07 18.6353 0 12Z' fill='%2310059F'/%3e%3cpath d='M10.8 17.8588L16.6588 12L10.8 6.14118L11.7882 5.15294L18.6353 12L11.7882 18.8471L10.8 17.8588Z' fill='white'/%3e%3cpath d='M17.6471 11.2941V12.7059L5.64706 12.7059V11.2941L17.6471 11.2941Z' fill='white'/%3e%3c/svg%3e ");
}
.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s, fill 0.3s; /* Smooth transition for hover effect */
}

.item-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    border-bottom: 2px solid #DFDFDF;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.item-name {
    margin-right: 8px; /* Space between text and arrow */
}

.item:hover .arrow svg path {
    fill: #ffffff00;
}
a.item-link .arrow, a.item-link .arrows {
    font-size: 24px;
    position: relative;
    font-family: 'NexaWebRegular';
    top: 0%;
    cursor: pointer;
}
a.item-link span.item-name {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #DFDFDF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

@media (max-width: 440px) {
.item-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    border-bottom: 2px solid #DFDFDF;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
}