

.navbrand__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 24px;
    width: 18%;
}

.navbrand {
       height: 40px;
    width: 100%;
    justify-content: center;
    font-weight: bolder;
    margin: 0 auto;
    text-align: left;
    text-align: center;
    position: relative;
    display: inline-flex;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .navbrand__container  {
        align-self: center;
    }
	.navbrand {
        height: 40px;
    width: 100%;
    justify-content: center;
    font-weight: bolder;
    margin: 0 auto;
    text-align: left;
    text-align: center;
    position: relative;
    display: inline-flex;
    align-items: center;
}
}