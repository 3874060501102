@media (min-width: 430px) and (max-width: 440px) {
    div#root .featured__products__container {
        display: inline-block;
        margin-bottom: 0 !important;
        max-height: 100%;
        width: 100%;
        margin-top: 20px ! IMPORTANT;
    }
}/* Additional CSS */
.category-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.category-grid.desktop .category-card {
    flex-basis: calc(33.33% - 20px);
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-grid.tablet .category-card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on tablet */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-grid .category-card {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 2560px) {
    .featured__products {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    .header__container, .main-container, .footer__items__container, .fotter__copyright__container {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    .control__bar, .control__bar__container, .controls__container {
        align-items: center;
        padding: 0;
    }
    .footer__items__container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100%;
        justify-content: flex-start;
        margin: 40px auto;
    }
    .fotter__copyright__container {
        align-items: center;
        background-color: #10059f;
        color: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 16px;
        justify-content: space-between;
        margin: 10px 0 40px auto;
    }
    .control {
        cursor: pointer;
        height: 24px;
        justify-content: center;
        margin: 0 auto;
        width: 24px;
    }
}

@media (max-width: 1920px) {
    .featured__products__header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
    }
}

@media (max-width:1440px) {
    .featured__products__header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
    }
    .featured__products {
        max-height: 100%;
    }
}

@media (min-width: 360px) and (max-width: 390px) {
    .product__card__card {
        width: 47% !important;
        margin: 0;
    }
}
@media (min-width: 390px) and (max-width: 440px) {
    .product__card__card {
        width: 47% !important;
        margin: 0;
    }
    .item-list {
        grid-gap: 0;
        align-content: space-between;
        align-items: stretch;
        display: flex;
        display: block;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0;
        height: auto;
        justify-content: flex-start;
        margin: 40px auto !important;
        position: relative;
        width: 100%;
    }
}

@media (min-width: 290px) and (max-width: 440px) {
    .featured__categories__container .featured__categories .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container {
        padding: 0;
    }
    .slid {
        width: 100% !important;
    }
    .image-wrapper:hover .overlay, .overlay {
        opacity: 1;
    }
    .category__card, .category__image a {
        display: flex;
        align-items: center;
        grid-gap: 0;
        gap: 0;
        align-content: center;
        flex-direction: row;
        justify-content: center;
    }
    .category__image a>img {
        max-width: 100%;
        height: auto !important;
        transition: 1s;
    }
    .category__name {
        height: 50%;
        width: 100%;
        font-size: 56px;
        font-weight: 400;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    .category__card__detail a .category__name span {
        font-size: 36px;
        text-align: center !important;
        color: #ffffff;
        width: 100%;
        top: 2px;
    }
    .category__card__detail a .category__name p {
        font-size: 16px;
        color: #ffffff;
        text-align: center !important;
    }
    .category__card__detail a .category__name p svg path {
        fill: #fff;
        color: #fff;
    }
    .category__card__detail {
        position: absolute !important;
        text-align: center;
        top: 170px;
        left: 0;
        width: 100% !important;
        z-index: 2;
    }
    .category__card__card {
        width: 100% !important;
        margin: 0 0 auto !important;
        padding: 0;
    }
    .featured__products .product__card__card {
        width: 100% !important;
        margin: 0;
    }
    .control .wishlist-button span.MuiBadge-root.BaseBadge-root svg:hover {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
        background-size: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    .featured__categories .slick-list, .slick-slider {
        display: block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
    }
    .d-flex.min-vh-100.w-100.justify-content-center.align-items-center.m-auto {
        min-height: 30.5vh !important;
    }
    .slick-slide {
        width: 100% !important;
        margin: 0 !important;
        height: 249px !important;
    }
    .featured__categories__card__container, .featured__categories__card__container .slick-slider .slick-list, .featured__categories__card__container .slick-slider .slick-list .slick-track, .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide, .featured__categories__container {
        border-radius: 2px;
        gap: 0;
        margin: 0 auto !important;
                padding: 0 16px;
        overflow: hidden !important;
    }
    .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
        min-width: 100% !important;
    }
    .new__products .product__card__card {
        margin: 0;
        width: 100% !important;
    }
    .slick-track {
        grid-gap: 0;
        gap: 0;
        margin: 0 0 0 !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
    }
    .featured__items__header__big {
        font-size: 24px !important;
        font-family: 'NexaWebRegular';
        font-weight: 400;
        text-transform: lowercase;
        margin: 0 !important;
    }
    .category__card, .category__image a {
        width: 100% !important;
        display: block !important;
        position: relative;
        align-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .featured__products__container {
        margin: 0px 0 auto !important;
    }
}
.featured__products__container {
    width: 100%;
    display: inline-block;
    margin: 24px 0 auto;
}
.featured__products {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    margin-top: 0px;
}
.featured__items__header__big {
    font-size: 64px;
    font-family: 'NexaWebRegular';
    font-weight: 400;
    text-transform: lowercase;
}
.featured__products__card__container {
    margin-top: 43px;
    padding: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    gap: 24px;
    margin-bottom: 104px;
}
.arrow {
    font-size: 24px;
    color: #ffe26e;
    position: relative;
    top: 50%;
    cursor: pointer;
}
.arrows {
    font-size: 24px;
    position: relative;
    top: 50%;
    cursor: pointer;
    gap: 44px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.next {
    right: 10px;
}
.prev {
    left: 10px;
}
.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0 !important;
}
.slick-slide {
    margin: 0 auto;
    margin-right: 24px;
}
.slick-slide:last-child {
    margin-right: 0; /* Убираем отступ у последнего элемента */
}
.align-items-center {
    align-items: flex-start !important;
}
.arrow.prev svg path {
    color: #bbb;
    fill: #bbb;
}
.arrow.prev svg path:hover, 
.arrow.next svg path:hover {
    color: #10059f;
    fill: #10059f;
}
.slick-list, 
.slick-slider {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 1920px) {
    .new__products .slick-track {
        grid-gap: 0;
        gap: 6px;
        margin: 0 auto;
    }
}

@media (max-width: 1706.67px) {  
.product__image>img {
        height: 100%;
        min-height: 354.88px;
        object-fit: cover !important;
    }  
    .new__products .slick-track {
        grid-gap: 0;
        gap: 6px;
        margin: 0 auto;
    }
    .slick-initialized .slick-slide {
        display: block;
        margin-right: 0px;
        margin-top: 0px;
    }
    .category-grid.desktop .category-card {
        flex: 1 1 calc(33.33% - 20px);
        margin-bottom: 104px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .category-grid.desktop .category-card:nth-last-child(-n+3) {
        margin-bottom: 0;
    }
    .category-grid.desktop .category-card:nth-child(n+1):nth-child(-n+3) {
        margin-bottom: 0;
    }
    .featured__products {
        max-height: 100%;
    }
    .featured__products__container {
        width: 100%;
        display: inline-block;
        margin: 24px 0 auto;
    }
}

@media (max-width: 1536px) {    
.new__products .slick-track {
        gap: 6px;
        margin: 0 auto;
        width: 100%;
    }
    .category-grid.desktop .category-card {
        flex: 1 1 calc(33.33% - 20px);
        margin-bottom: 104px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .category-grid.desktop .category-card:nth-last-child(-n+3) {
        margin-bottom: 0;
    }
    .category-grid.desktop .category-card:nth-child(n+1):nth-child(-n+3) {
        margin-bottom: 0;
    }
    .featured__products__container {
        display: inline-block;
        margin: 104px 0 114px;
        width: 100%;
    }
    .new__products {
        margin-bottom: 0px;
        flex-direction: column;
        padding-top: 0;
    }
    .new__products__container {
        width: 100%;
        margin: 0 0 120px auto;
    }
}

@media (max-width: 1440px) {
	    .product__image>img {
        height: 100%;
        min-height: 232.85px;
        object-fit: cover !important;
    }
    .category-grid.desktop .category-card {
        flex: 1 1 calc(33.33% - 20px);
        margin-bottom: 104px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .category-grid.desktop .category-card:nth-last-child(-n+3) {
        margin-bottom: 0;
    }
    .category-grid.desktop .category-card:nth-child(n+1):nth-child(-n+3) {
        margin-bottom: 56px;
    }
}

@media (min-width: 768px) and (max-width: 1366px) {
	
	.custom-carousel-item .carousel__image__container .category__card__card .category__card__detail {
        left: 0;
        position: relative !important;
        text-align: center;
        width: 100%;
        z-index: 2;
    }
    .category-grid.desktop .category-card {
        flex: 1 1 calc(33.33% - 20px);
        margin-bottom: 104px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .category-grid.desktop .category-card:nth-last-child(-n+3) {
        margin-bottom: 0;
    }
    .category-grid.desktop .category-card:nth-child(n+1):nth-child(-n+3) {
        margin-bottom: 56px;
    }
}

@media (max-width: 1228.8px) {
    .category-grid.desktop .category-card {
        flex: 1 1 calc(33.33% - 20px);
        margin-bottom: 104px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .category-grid.desktop .category-card:nth-last-child(-n+3) {
        margin-bottom: 0;
    }
    .category-grid.desktop .category-card:nth-child(n+1):nth-child(-n+3) {
        margin-bottom: 56px;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.product__image>img {
        border: none;
        height: 500px !important;
        margin: 0 auto;
        max-width: 100%;
        transition: 1s;
    }
}
@media (min-width: 768px) and (max-width: 810px) {
    .featured__categories__container .featured__categories .featured__categories__card__container .slid {
        width: 30% !important;
        margin: 0 auto;
        padding: 0;
        position: relative;
        overflow: hidden;
    }
    .featured__products__container {
        margin: 0px 0 auto !important;
    }
    .carousel__item, .slick-track .slick-slide {
        max-width: 242px !important;
        margin: 0 !important;
        min-width: 242px !important;
    }
	.new__products .product__card__card {
        margin: 0;
        width: 100% !important;
    }
    .product__card__card {
        width: 33% !important;
        margin: 0;
    }
    .product__image>img {
        max-width: 100%;
        height: 284px !important;
        transition: 1s;
        margin: 0 auto;
        border: none;
    }
	.featured__products .d-flex .slick-slider .slick-list .slick-track, .product__card__card {
        height: 100% !important;
    }
	    .slick-track .slick-slide {
        height: 100% !important;
    }
	.item-list {
        margin: 40px auto !important;
	}
	.featured__categories__card__container {
        grid-gap: 56px 24px !important;
        gap: 56px 24px !important;
	}
	.category__name span {
        font-size: 32px;
    }
	.slick-track {
        height: 100% !important;
        grid-gap: 0px !important;
        gap: 0px !important;
    }    
	.slick-list, .slick-slider {
        height: 100% !important;
		box-sizing: border-box;
    }
    .d-flex.min-vh-100.w-100.justify-content-center.align-items-center.m-auto {
        min-height: 100% !important;
    }
	.new__products__container {
        width: 100%;
        margin: 0 auto;
    }    


}
@media  (min-width: 275px) and (max-width:1024px) {
	.featured__products {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    margin-top: 24px !important;
}
	.landing__image {
        height:55dvh!important;
        position: relative;
        width:100% !important;
	}
    .slick-track {
        grid-gap: 26px;
        gap: 63px;
    }
}
@media  (min-width: 768px) and (max-width: 1024px) {
	 .new__products {
        flex-direction: column;
        margin-bottom: 0;
        padding-top: 0;
    }
	.category__card__container .category__product__card .product__card__card {
        margin: 0;
        width: 48.79% !important;
		padding-bottom: 20px;
    }
    .featured__categories__container {
        margin-bottom: 30px;
        overflow: hidden;
        padding: 0 16px !important;
        width: 100%;
    }
	.carousel__image__container {
    align-items: center!important;
    display: flex!important;
    height: 100%; 
    justify-content: center!important;
    width: 100%;
    flex-direction: column!important;
    flex-wrap: nowrap!important;
    align-content: center!important;
	}
    .slick-track {
        grid-gap: 1px !important;
        gap: 1px !important;
    } 
	.featured__categories__container .featured__categories .featured__categories__card__container .slick-slider .slick-list .slick-track {
        height: 100% !important;
        grid-gap: 0px !important;
        gap: 0px !important;
    }
    .item-list {
        margin: 40px auto !important;
        height: 100% !important;
    }
	.featured__products__container {
       max-height: 100% !important;
	   margin: 40px 0 auto;
    }
	.d-flex.min-vh-100.w-100.justify-content-center.align-items-center.m-auto {
        min-height: 100% !important;
    }
	.featured__products {
        max-height:100% !important;
	}
    .slick-list, .slick-slider {
        height: 100% !important;
		box-sizing: border-box;
    }
    .slick-track .slick-slide {
        min-height: 100% !important;
    }   
	.carousel__item, .slick-track .slick-slide {
        max-width: 5% !important;
        margin: 0 !important;
        min-width: 5% !important;
    }    
	.product__card__card {
        height: 100% !important;
        width:100% !important;
        margin: 0;
    }    
	.featured__products .d-flex .slick-slider .slick-list .slick-track {
        height: 100% !important;
    }  

	.custom-carousel-item {
    width: 100%;
    display: inline-block;
    transition: transform 0.5s ease-in-out;
    }
    .product__carousel .carousel.carousel-dark .carousel-inner {
        overflow: scroll;
        position: relative;
        width: 100%;
    }
	.product__image>img {
        max-width: 100%;
        transition: 1s;
        margin: 0 auto;
        border: none;
    }
	 .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
        min-width: 100% !important;
    }
.category__name p svg {
    margin-left: 17px;
}
span.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.MuiBadge-colorError.BaseBadge-badge {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
}
}
@media (min-width: 380px) and (max-width: 435px) {   
.featured__categories__container {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.featured__categories {
    width: 100%;
    position: relative;
}

    .custom-carousel-item {
        display: inline-block;
        max-width: 386px;
        min-width: 386px;
        padding: 0;
        width: 100%;
    }
.carousel__image__container {
    width: 100%;
}

.category-card {
   
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} 
    .new__products .slick-track {
        grid-gap: 0 !important;
        gap:1px !important;
        margin: 0 auto;
    }
   .featured__products {
    }
	.carousel.slide.carousel-dark {
    padding: 0 !important;
    width: 100%;
}
	.carousel.slide.carousel-dark {
    padding: 0 !important;
}
    .category__name {
        width: 100%;
        font-size: 40px; /* Убрал !important, если возможно */
    }
    
    .category__card__card {
        width: 100%; /* Убрал !important, если возможно */
        margin: 0 auto; /* Если margin: 0 0 auto, не будет работать, можно оставить только auto */ 
        padding-right: 0; /* Убрал px, так как значение 0 */
    }

    .category__container {
        height: auto;
        width: 100%;
        margin: 0 auto;
    }
    
    .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
        min-width: 100%; /* Убрал !important, если возможно */
    }
    
    .category__card, 
    .category__image a {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        align-content: center !important;
        flex-wrap: nowrap !important;
        flex-direction: row;
    }
        .image-wrapper:hover .overlay, .overlay {
        opacity: 1 !important;
        width: 100% !important;
    }
    .featured__categories__card__container .slid {
        width: 100% !important;
    }
	.featured__categories__card__container .category__card, .featured__categories__card__container .category__image a {
    display: block;
    width: 100% !important;
}    .category__card__card .category__image a>img {
        max-width: 100%;
        transition: 1s;
    }
	    .category__card__detail {
left: 0;
        position: absolute !important;
        text-align: center;
        top: 200px !important;
        width: 100% !important;
        z-index: 2;
    }
	.category__card__card .category__card__detail a .category__name span {
        font-size: 40px;
        text-align: center!important;
        color: #fff;
        width: 100%;
    }
	
	.slick-list, .slick-slider {
        display: block;
        position: relative;
        width: 100%;
		box-sizing: border-box;
    }
	
.featured__categories .slick-dots li.slick-active button::before {
    width: 18px;
    color: #10059f !important;
    height: 18px;
    font-size: 12px !important;
}
.featured__categories .slick-dots li button::before {
    width: 18px;
    color: #fff !important;
	opacity: .6;
    height: 18px;
    font-size: 8px !important;
}
	.item-list {
    margin:24px 0 40px auto!important;
}
	.category__card__card .category__card__detail a .category__name span {
        font-size: 32px;
        text-align: center!important;
        color: #fff;
        width: 100%;
    }

	.category__card__card .category__card__detail a .category__name p {
        font-size: 16px;
        color: #ffffff;
        text-align: center !important;
    }
.featured__products__container {
    width: 100%; /* Или конкретная ширина, если требуется */
    overflow: hidden; /* Это поможет избежать переполнение */
}
.new__products .slick-list, .new__products .slick-slider {
	box-sizing: border-box;
}
div#root .new__products__container {
        width: 100%;
        margin: 0 0 120px auto; 
		overflow: hidden;
    }
div#root .featured__products__container { 
display: inline-block;
        max-height: 100%;
        width: 100%;
        margin-bottom: 0px !important;
    }
.shop__category__product__card {
        grid-gap:11px !important;
        gap: 11px !important;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between !important;
}    
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 24px;
        margin: 0 !important;
    }
    .navbrand__container .navbrand__container h1.navbrand svg {
        height: 18px !important;
        left: 0 !important;
        position: relative !important;
        top: 0 !important;
        width: 126px !important;
    }    
	.featured__categories__container .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 56px;
        padding: 0;
        width: 100% !important;
    }
	li.slick-active button::before {
    color: #10059f !important;
    opacity: 1 !important;
    font-size: 12px;
}
.slick-dots li button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff !important;
    content: "•";
    font-family: slick;
    font-size: 6px !important;
    height: 20px;
    left: 0;
    line-height: 20px;
    opacity: 1;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}
}

@media (max-width:375px) {    
    .featured__categories .category__card__detail {
        left: 0;
        position: absolute !important;
        text-align: center;
        top: 170px;
        width: 342px !important;
        z-index: 2;
    }
    .new__products .slick-track {
        grid-gap: 0;
        gap:1rem !important;
        margin: 0 auto;
    }
	    .custom-carousel-item {
        display: inline-block;
        padding: 0;
        max-width: 341px !important;
        min-width: 341px !important;
        width: 100%;
    }
	    .featured__categories__container .featured__categories .slick-slider .slick-list .slick-track .slick-slide {
        margin-bottom: 56px;
        padding: 0;
        width: 100% !important;
    }
.featured__categories {
    width: 100%;
    position: relative;
}
    .category__name {
        font-size: 40px !important;
        width: 341px !important;
    }
	.category__card__detail a {
    display: block;
    height: 78px;
    width: 341px !important;
}
.category__card__card .category__card__detail a .category__name span {
        color: #fff;
        font-size: 32px;
        text-align: center !important;
        width: 341px !important;
    }
   .featured__products {
        max-height: 362px !important;
    }
.new__products .slick-track {
    grid-gap: 0;
    gap: 24px !important;
    margin: 0 auto;
}	
	.category__image img {
    height: 100%;
    object-fit: cover !important;
    width: 100% !important;
}
    .image-wrapper:hover .overlay, .overlay {
        opacity: 1 !important;
        width: 100% !important;
    }
	.new__products .slick-track {
       grid-gap: 17px !important;
        gap: 23px !important;
    }
	.featured__products .slick-track {
       grid-gap: 17px !important;
        gap: 23px !important;
    }
}