@media (min-width:1024px) and (max-width:1366px) {
	
.nav__top__container {
    align-items: center;
    display: flex;
    height: 75px;
    justify-content: center;
    margin: 0 auto;
    padding: 0 16px;
}
}
	@media (min-width:290px) and (max-width:1024px) {
.nav__top__container {
    width: 100% !important;
    justify-content: center;
    margin: 0 auto;
}
.nav__top__container, .top__container {
    display: flex;
    padding: 0px !important;
    align-items: center !important;
}
.nav__top__container, .top__container {
    height: 75px;
    display: flex !important;
    align-items: center !important;
    flex-direction: row-reverse !important;
}
        .mobile__layout .control__bar__container {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 40px;
        justify-content: flex-end;
        margin: 0;
        position: relative;
        width: 63px;
        z-index: 1000;
    }
	.mobile__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
}
    .burger {
        display: block;
        height: 40px;
        margin: 0 auto;
        padding: 8px 3px;
        position: relative;
        width: 25px;
    }
    .burgersearch {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
	}
    .desktop__layout {
        display: none !important;
    }
.controls__container {
    grid-gap: 0px;
    gap: 0px;
    height: 40px !important;
}
    span.MuiBadge-root.BaseBadge-root {
        width: 40px;
        height: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    a.wishlist-button {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        padding: 0px;
    }
p.border {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px;
    gap: 10px;
    border: 1px solid #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
	        margin-top: 16px;
}
.mobile__layout {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
}
    .mobile__list svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge {
        width: 40px;
        height: 40px;
        left: 0px;
        position: relative;
    }
    .mobile__list .navbrand__container {
        left: 0;
        margin: 0 auto;
        padding: 0;
        position: relative;
        width: 187px;
   
    }
    .mobile__list svg, .mobile__list svg path {
        height: 18px !important;
        margin: 0;
        padding: 0;
        width: 18px !important;
    }
    .mobile__list .control {
        width: 31px;
        height: 40px;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
    }
    .mobile__list {
        padding: 0;
        grid-auto-flow: column;
        display: grid;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
    }
a.wishlist-button.active {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 2.02154C9.87873 0.755486 11.3727 0 13.0909 0C14.3924 0.00147795 15.6402 0.506254 16.5605 1.4036C17.4808 2.30094 17.9985 3.51757 18 4.7866C18 7.11609 16.5109 9.5405 13.5736 11.9912C12.2277 13.1095 10.7738 14.098 9.23236 14.943C9.16093 14.9804 9.0811 15 9 15C8.91891 15 8.83907 14.9804 8.76764 14.943C7.2262 14.098 5.77232 13.1095 4.42636 11.9912C1.48909 9.5405 0 7.11609 0 4.7866C0.00151577 3.51757 0.519209 2.30094 1.43951 1.4036C2.35982 0.506254 3.60758 0.00147795 4.90909 0C6.62727 0 8.12127 0.755486 9 2.02154Z' fill='%2310059F'/%3e%3c/svg%3e ");
	background-position: center !important;
        background-repeat: no-repeat;
        background-size: 18px !important;
		display: flex !important;
}
a.cart-button {
    height: 18px !important;
    display: block;
    padding: 0 5px 28px;
}
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeLarge:hover svg path {
    fill: #10059f;
}
a.wishlist-button.active {
    display: block;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 2.02154C9.87873 0.755486 11.3727 0 13.0909 0C14.3924 0.00147795 15.6402 0.506254 16.5605 1.4036C17.4808 2.30094 17.9985 3.51757 18 4.7866C18 7.11609 16.5109 9.5405 13.5736 11.9912C12.2277 13.1095 10.7738 14.098 9.23236 14.943C9.16093 14.9804 9.0811 15 9 15C8.91891 15 8.83907 14.9804 8.76764 14.943C7.2262 14.098 5.77232 13.1095 4.42636 11.9912C1.48909 9.5405 0 7.11609 0 4.7866C0.00151577 3.51757 0.519209 2.30094 1.43951 1.4036C2.35982 0.506254 3.60758 0.00147795 4.90909 0C6.62727 0 8.12127 0.755486 9 2.02154Z' fill='%2310059F'/%3e%3c/svg%3e ");
	background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 24px;
    height: 22px;
    width: 24px;
}
h1.navbrand svg {
    width: 126px;
}
.nav__top__container{
    align-items: center;
    display: flex;
    height: 75px;
    justify-content: center;
    margin: 0 auto;
    padding: 0 200px;
}

.top__container {
    height: 75px;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.form__container {
    width: 50%;
    min-height: 10px;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-self: center;
}

.control__bar {
    align-self: center;
    height: auto;
    width: auto;
    z-index: 1000;
}

.drawer {
    display: none;
    height: auto;
    width: 40px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1100px) {
	.nav__top__container {
    align-items: center;
    display: flex;
    height: 75px;
    justify-content: center;
    margin: 0 auto;
    padding: 0 16px;
}
}
@media screen and (max-width: 1024px) {
	.mobile__layout {
    padding: 0px 16px;
}
}
@media screen and (max-width: 820px) {
	.mobile__layout {
    padding: 0px;
}
}
@media screen and (max-width: 810px) {
	.mobile__layout {
    padding: 0px;
}
}
@media screen and (max-width: 768px) {
	.mobile__layout {
    padding: 0px;
}
}
@media screen and (max-width: 430px) {
	.mobile__layout {
    padding: 0px!important;
}
}
@media screen and (max-width: 500px) {
    .nav__top__container {
        height: auto;
    }
    .form__container {
        display: none;
    }
    .top__container {
        justify-content: space-between;
    }
}


.nav__top__container {
    width: 100%;
}

.top__container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.desktop__layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.mobile__layout {
    display: none; 
}