@media (min-width:290px) and (max-width:1024px) {
.detail__and__carousel__container {	
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center !important;
    flex-direction: row;
    flex-wrap: wrap !important;
    margin-top: 24px;
}
	.item__container {
    height: auto;
    width: 100%;
    padding: 0px !important;
}
.item__container {
    height: auto;
    padding: 0 16px;
    width: 100%;
}
}  
.item__container {
    height: auto;
    width: 100%;
    padding: 0 200px;
}

@media (max-width: 1180px) {
	.item__container {
    height: auto;
    padding: 0 16px;
    width: 100%;
}
}
@media (max-width: 1024px) {
.detail__and__carousel__container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
        justify-content: flex-start;
    margin-top: 0;
    overflow: hidden;
    width: 100%;
    gap: 24px;
	    margin-bottom: 40px !important;
}
}
.product__carousel .carousel.carousel-dark .carousel-inner {
    overflow: scroll;
    position: relative;
    width: 100%;
    height: auto;
}
.item__description__container {
    height: auto;
    width: 100%;
}

.related__items__container {
    height: auto;
    width: 100%;
}
@media (max-width: 1920px) {
.detail__and__carousel__container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
        justify-content: flex-start;
    margin-top: 0;
    overflow: hidden;
    width: 100%;
    gap: 23px;
	    margin-bottom: 120px;
}
}
@media (max-width: 1536px) {
.detail__and__carousel__container {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
        height: 100%;
        justify-content: flex-start;
        margin-bottom: 104px;
        margin-top: 0;
        overflow: hidden;
        width: 100%;
        align-content: flex-start;
    }
}