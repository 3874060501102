.promoCode table {
    width: 100%;
    margin: 40px auto;
}
.admin-container button:hover {
    background-color: #10059f !important;
}
.promoCode {
  margin: 0 auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.promoCode button {
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}
.promoCode button {
    width: 100%;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    position: relative;
    display: block;
    padding: 0 !important;
    height: 45px;
    border-radius: 5px;
}
.promoCode button:hover {
  background-color: #10059f !important;
}

.error-message {
  color: red;
  font-size: 12px;
}