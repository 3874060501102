
.breadcrumbs {
    list-style: none;
    padding: 0;
    display: block;
    margin: 0 auto;
    width: 100%;
}

.breadcrumbs li {
    display: inline;
}

.breadcrumbs li a {
    text-decoration: none;
    color: #bbb;
    text-transform: lowercase;
}

.breadcrumbs li span {
    color: #1B1B1B;
    text-transform: lowercase;
}
.separator {
    color: #bbb;
    padding: 0 8px;
    height: 17.6px;
    display: inline-block;
    top: 2px;
    position: relative;
}
.breadcrumbs a {
    color: #bbb;   
	text-transform: lowercase;
    font-size: 14px;
}
.breadcrumbs a:last-of-type {
    color: #1b1b1b;
    font-weight: 400;
}

nav.breadcrumbs {
    margin: 16px 0 16px auto!important;
}

@media (max-width:1920px) {
nav.breadcrumbs {
    margin: 16px 0 20px !important;
}	
}
nav.breadcrumbs a:hover {
    color: #10059f;
}

@media (max-width:1440px) {
	    nav.breadcrumbs {
        margin: 24px 0 24px auto !important;
    }
}
@media (max-width:440px) {
	
.breadcrumbs li {
    display: none !important;
}
}
@media (max-width:1536px) {
nav.breadcrumbs {
    margin: 10px 0 24px auto !important;
}
}