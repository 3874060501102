@media (min-width: 390px) and (max-width: 440px) {

    .product__card__card {
        width: 100% !important;
        margin: 0;
    }  
}
@media (min-width: 100px) and (max-width: 290px) {
    .product__card__card {
        margin: 0;
        width: 47% !important;
    }
}
@media (min-width: 290px) and (max-width: 440px) {    
.slid {
        width: 100% !important;
    }
	    .image-wrapper:hover .overlay, .overlay {
        opacity: 1;
    }
	    .category__card, .category__image a {
        display: flex;
        align-items: center;
        grid-gap: 0;
        gap: 0;
        align-content: center;
        flex-direction: row;
        justify-content: center;
    }
    .category__image a>img {
        max-width: 100%;
        height: auto !important;
        transition: 1s;
    }
	.category__image {
    width: 99%;
    margin: 0 auto;
	transition: transform 0.5s ease-in-out;
    padding: 0;
    display: block;
    position: relative;
}

.category__name {
    height: 50%;
    width: 100%;
    font-size: 56px;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
}
    .category__card__detail a .category__name span {
        font-size: 36px;
        text-align: center !important;
        color: #ffffff;
        width: 100%;
        top: 2px;
    }
	.category__card__detail a .category__name p {
        font-size: 16px;
        color: #ffffff; 
        text-align: center !important;
    }
	.category__card__detail a .category__name p svg path {
    fill: #fff;
    color: #fff;
}
.category__card__detail {
        position: absolute !important;
        text-align: center;
        top: 170px;
        left: 0;
        width: 100% !important;
        z-index: 2;
    }
	    .category__card__card {
        width: 100% !important;
        margin: 0 0 auto !important;
        padding: 0;
    }
	.product__card__card {
        width: 100%!important;
        margin: 0;
    }
.control .wishlist-button span.MuiBadge-root.BaseBadge-root svg:hover {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
}
	.slick-list, .slick-slider {
    position: relative;
    display: block;
    width: 100%;
}
    .featured__products {
        padding: 0px 0px!important;
    }
	.d-flex.min-vh-100.w-100.justify-content-center.align-items-center.m-auto {
    min-height: 30.5vh !important;
}
    .slick-slide {
        width: 100% !important;
        margin: 0!important;
    }
.featured__categories, .featured__categories__card__container, .featured__categories__card__container .slick-slider .slick-list, .featured__categories__card__container .slick-slider .slick-list .slick-track, .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide, .featured__categories__container {
        border-radius: 2px;
        gap: 0;
        margin: 0 auto !important;
        padding: 0 0px;
    }
	.featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
    min-width: 100% !important;
}
    .d-flex.min-vh-100 {
        margin-bottom: 40px;
    }

    .slick-track {
        grid-gap: 0;
        gap: 0;
        margin: 0 0 0 !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
    }
    .featured__items__header__big {
        font-size: 24px !important;
        font-family: NexaWebRegular;
        font-weight: 400;
        text-transform: lowercase;
        margin: 0 !important;
    }
	.category__card, .category__image a {
        width: 100% !important;
        display: block !important;
        position: relative;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

	.featured__products__container {
    margin: 0px 0 auto !important;
}
}
.featured__products__container {
    width: 100%;
    display: inline-block;
    margin: 120px 0 auto;
}
.featured__products {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featured__products__header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
}

.featured__items__header__big {
    font-size: 64px;
    font-family: 'NexaWebRegular';
    font-weight: 400;
    text-transform: lowercase;
}

.featured__products__card__container {
    margin-top: 43px;
    padding: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    gap: 24px;
    margin-bottom: 104px;
}

.arrow {
    font-size: 24px;
    color: #ffe26e;
    position: relative;
    top: 50%;
    cursor: pointer;
}

.arrows {
    font-size: 24px;
    position: relative;
    top: 50%;
    cursor: pointer;
    gap: 44px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}

.next {
    right: 10px;
}

.prev {
    left: 10px;
}
.featured__products .slick-list .slick-track {
    grid-gap: 6px;
    flex-direction: row !important;
    gap: 4px;
    justify-content: flex-start !important;
    margin: 0 auto;
    width: 100% ;
}
.new__products .slick-list .slick-track {
    grid-gap: 4px;
    flex-direction: row !important;
    gap: 4px;
    justify-content: flex-start !important;
    margin: 0 auto;
    width: 100%;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0 !important;
}

.slick-slide {
    width: 22rem;
    margin: 0 auto;
}

.slick-slide:last-child {
    margin-right: 0; /* Убираем отступ у последнего элемента */
}

.align-items-center {
    align-items: flex-start !important;
}

.arrow.prev svg path {
    color: #bbb;
    fill: #bbb;
}

.arrow.prev svg path:hover, 
.arrow.next svg path:hover {
    color: #10059f;
    fill: #10059f;
}

.slick-list, 
.slick-slider {
    position: relative;
    display: block;
    width: 100%;
}


@media (max-width: 1706.67px) {

.featured__products {
    max-height: 400px;
}

.featured__products__container {
    width: 100%;
    display: inline-block;
    margin: 120px 0 auto;
}
}
@media (max-width: 1536px) {
	
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 28px;
        margin: 0 !important;
    }

    .featured__products__container {
        display: inline-block;
        margin: 120px 0 94px;
        width: 100%;
    }
	.new__products {
    margin-bottom: 0px;
    flex-direction: column;
    padding-top: 0;
}
.new__products__container {
    width: 100%;
    margin: 0 0 120px auto;
}

}
@media (max-width: 1440px) {
	
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 28px;
        margin: 0 !important;
    }

}

@media  (min-width: 900px) and (max-width: 1024px) {
	
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 27px;
        margin: 0 !important;
    }
.featured__categories__container .featured__categories .featured__categories__card__container .slid {
        width: 30% !important;
        margin: 0 auto;
        padding: 0;
        position: relative;
    }   
	.featured__products__container {
    margin: 40px 0 auto;
}
.carousel__item, .slick-track .slick-slide {
        max-width: 100%!important;
        margin: 0 !important;
    }
	    .product__card__card {
        width: 100% !important;
        margin: 0;
    }    
	.product__image>img {
        max-width: 100%;
        height: 100% !important;
        transition: 1s;
        margin: 0 auto;
        border: none;
    }
	.item-list {
        margin: 40px auto;
	}
	.featured__categories__card__container {
        grid-gap: 56px 24px !important;
        gap: 56px 24px !important;
	}
	.category__name span {
        font-size: 32px;
    }
	.slick-track {
        grid-gap: 24px !important;
        gap: 24px !important;
    }    
	.new__products__container {
        width: 100%;
        margin: 0 auto;
    }    

}
@media  (min-width: 768px) and (max-width: 810px) {
	div#root .featured__products__container {
    display: inline-block;
    margin-bottom: 0 !important;
    max-height: 100%;
    width: 100%;
    padding: 20px 100px;
}
.category__card__card .category__card__detail a .category__name p {
    color: #1b1b1b;
    font-size: 14px;
    text-align: center !important;
}
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 27px;
        margin: 0 !important;
    }
    .slick-track {
        grid-gap: 0px !important;
        gap: 0px !important;
    } 
	.featured__categories__container .featured__categories .featured__categories__card__container .slick-slider .slick-list .slick-track {
        grid-gap: 0px !important;
        grid-gap: 0 !important;
        gap: 0px !important;
    }
    .item-list {
        margin: 40px auto !important;
    }
	.featured__products__container {
	   margin: 40px 0 auto;
    }
	.carousel__item, .slick-track .slick-slide {
        margin: 0 !important;
        max-width: 261px !important;
        min-width: 261px !important;
    } 
    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 475px!important;
    }	
	.product__card__card {
        margin: 0;
    }
	.featured__products .d-flex .slick-slider .slick-list .slick-track {
        height: 520px !important;
    }  


	.product__image>img {
        max-width: 100%;
        transition: 1s;
        margin: 0 auto;
        border: none;
    }
	    .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
        min-width: 100% !important;
    }

}
.category__name p svg {
    margin-left: 0px;
}
span.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.MuiBadge-colorError.BaseBadge-badge {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
}
@media (min-width:375px) and (max-width:380px) {
	.product__card__card {
        margin: 0;
        width: 47% !important;
    }
}
@media (min-width:400px) and (max-width:460px) {
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 27px;
        margin: 0 !important;
    }
	.category__card__card .category__image a>img {
        max-width: 100%;
        object-fit: cover !important;
        transition: 1s;
        width: 100%;
    }    
	.category__image {
        display: block;
        margin: 0 auto;
        padding: 0;
        position: relative;
        top: 0;
        transition: transform .5s ease-in-out;
        width: 100%;
    }
}
@media (min-width:290px) and (max-width:375px) {
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 27px;
        margin: 0 !important;
    }
	.category__card__card .category__image a>img {
        max-width: 100%;
        object-fit: cover !important;
        transition: 1s;
        width: 100%;
    }    
	.category__image {
        display: block;
        margin: 0 auto;
        padding: 0;
        position: relative;
        top: 0px !important;
        transition: transform .5s ease-in-out;
        width: 100%;
    }
	  .category__name {
        width: 100%;
        font-size: 40px; /* Убрал !important, если возможно */
    }
    
    .category__card__card {
        width: 100%; /* Убрал !important, если возможно */
        margin: 0 auto; /* Если margin: 0 0 auto, не будет работать, можно оставить только auto */ 
        padding-right: 0; /* Убрал px, так как значение 0 */
    }

    .category__container {
        height: auto;
        width: 100%;
        margin: 0 auto;
    }
    
    .featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
        min-width: 100%; /* Убрал !important, если возможно */
    }
    
    .category__card, 
    .category__image a {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        width: 100% !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        align-content: center !important;
        flex-wrap: nowrap !important;
        flex-direction: row;
    }
        .image-wrapper:hover .overlay, .overlay {
        opacity: 1 !important;
    }
    .featured__categories__card__container .slid {
        width: 100% !important;
    }
	.featured__categories__card__container .category__card, .featured__categories__card__container .category__image a {
    display: block;
    width: 100% !important;
} 
.category__card__card .category__image a>img {
        max-width: 100%;
        transition: 1s;
        object-fit: cover !important;
    
    }
	    .category__card__detail {
        position: absolute !important;
        text-align: center;
        top: 200px !important;
        left: 0;
        width: 100% !important;
        z-index: 2;
    }
	.category__card__card .category__card__detail a .category__name span {
        font-size: 40px;
        text-align: center!important;
        color: #fff;
        width: 100%;
    }
}
@media (min-width:290px) and (max-width:460px) {
	
.category__header h2, .Checkout__container h1, .new__items__header__big, .related__header h2, .shop__category__head h2, .wishlist__header h2, h3.featured__items__header__big {
        font-size: 27px;
        margin: 0 !important;
    }
	.category__card__card .category__card__detail a .category__name span {
        font-size: 32px;
        text-align: center!important;
        color: #fff;
        width: 100%;
    }
}
	.category__card__card .category__card__detail a .category__name p {
        font-size: 16px;
        color: #1b1b1b;
        text-align: center !important;
    }
.featured__products__container {
    width: 100%; /* Или конкретная ширина, если требуется */
    overflow: hidden; /* Это поможет избежать переполнение */
}
.new__products .slick-list, .new__products .slick-slider {
    height: 100% !important;
}
div#root .new__products__container {
        width: 100%;
        height: 100% !important;
        margin: 0; 
		overflow: hidden;
    }
div#root .featured__products__container {
display: inline-block;
        max-height: 100%;
        width: 100%;
        margin-bottom: 0px !important;
    }
.shop__category__product__card {
    grid-gap: 6px !important;
    gap: 6px !important;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start!important;
}    
    .navbrand__container .navbrand__container h1.navbrand svg {
        height: 18px !important;
        left: 0 !important;
        position: relative !important;
        top: 0 !important;
        width: 126px !important;
    }

@media (min-width: 100px) and (max-width: 320px) {
    .product__card__card {
        margin: 0;
        width: 47% !important;
    }
}