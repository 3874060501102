
@media (min-width:290px) and (max-width:640px) {
.footer__items__container {
    max-width: 100% !important;
	flex-direction: column !important;
}
ul.footer__contacts {
    margin: 16px auto;
}
footer__contact__container {
    margin-top: 16px;
}
ul.footer__social__links {
    margin: 16px auto;
}
li.footer__privacy__policy {
    display: none !important;
}
.fotter__copyright__container {
    width: 100% !important;
    display: flex;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
	margin: 56px 0 40px 0 !important;
}
ul.nav {
    display: flex;
    flex-direction: column !important;
    align-content: flex-start !important;
    align-items: flex-start !important;
    justify-content: center !important;
}
.footer__help__container {
    width: 256px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
}
.footer__container {
    padding: 0 16px;
}
.footer__items__container {
    margin: 40px 0 auto !important;
}
li.footer__privacy__policy {
    display: none;
}
li.footer__contact.fonts span, li.footer__copyright{
    height: auto;
    line-height: 135%;
    display: block;
}
}
.footer__container {

    background-color: #10059f;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 0 auto !important;
}

.footer__items__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin: 40px auto;

}


.footer__help__header {
    height: 40%;
    width: 70%;
    margin: auto;
    display: flex;
}

.footer__help__header h1{
    font-size: 1.3rem;
    font-weight: 600;
    align-self: center;
}

.fotter__help__links {
    margin: 0%;
    padding: 0%;
    height: 100%;
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
}

.footer__contact__container {
    width: 256px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footer__contact__header {
    height: 40%;
    width: 100%;
    display: flex;
    justify-self: center;
    align-self: center;
}

.footer__contact__header h1{
    font-size: 1.3rem;
    font-weight: 400;
    align-self: center;
    color: #fff;
}
.footer__contacts {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.footer__contact {
    width: 100%;
    height: 30%;
    color: #fff;
    font-size: 18px;
    padding: 4px 0;
}

.footer__social__link__container {
    width: 300px;
    height: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center
}

.footer__social__link__header {
    height: 40%;
    width: 100%;
    display: flex;
}

.footer__social__link__header h1 {
    font-size: 1.3rem;
    font-weight: 400;
    align-self: center;
    color: #fff;
}

.footer__social__links {
   padding: 0;
    height: 70%;
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    margin: 100px 0 auto;
    gap: 12px;
}

.social__link {
    width: 12%;
}

.fotter__copyright__container {
    align-items: center;
    background-color: #10059f;
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    height: 16px;
    justify-content: space-between;
    margin: 10px 0 40px auto;
    width: 100%;
}
li.help__link a {
    color: #fff;
}
ul.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
ul.fotter__help__links li.help__link {
    font-size: 18px;
    text-transform: lowercase;
    color: #fff;
    font-weight: 400;
    padding: 4px 0;
}
li.help__link a:hover {
    color: #bbb;
}
li.footer__contact.fonts span {
    width: 233px;
    height: 32px;
    font-family: 'NexaTextDemo';
    font-style: normal;
    font-weight: 200 !important;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    display: block;
}
li.footer__copyright, li.footer__privacy__policy {
    font-family: NexaTextDemo;
    font-style: normal;
    font-weight: 200 !important;
    font-size: 12px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: block;
    font-size: 12px;
}
li.footer__contact span {
	
	font-family: 'NexaWebRegular';
}
footer{
    display: block;
    z-index: 9;
    position: relative;
}


@media (max-width: 1920px) {
.footer__help__container {
    width: 256px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 130px 0 0;
}
}
@media (max-width: 1536px) {
.footer__help__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1px 76px 0 0;
    width: 256px;
}
}
@media (max-width: 440px) {
	.social__link {
    width: 10% !important;
}
li.social__link svg {
    width: 24px;
    height: 24px;
}
}