
@media (min-width:290px) and (max-width:1024px) {
.thumbnail__container {
    display: none;
}
}
p {
    font-family: 'NexaWebRegular';
}
.product__description__product {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: rgb(246, 246, 246);
    align-self: center;
    margin: 50px auto;
    padding: 30px;
}

.description__header__container {
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.description__header__line {
    height: 50%;
    width: 2px;
    background-color: rgb(0, 0, 0);
}

.description__header {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.4rem;
}

.description__detail__container {
    width: 90%;
    height: 40%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.description__detail {
    width: 60%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.description__highlights__header {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}


.description__specifics__container {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.custom-accordion {
    border-radius: 5px;
    border-top: 1px solid #dfdfdf;
    margin: 40px 0 0;
    border-radius: 0;
    overflow: hidden;
    width: 100%;
}
.accordion-body {
    background: #fff;
    padding: 0px;
}
.accordion-body p {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.accordion-body ul {
    margin-bottom: 24px;
}
.accordion-body ul {
left: 0px;
    list-style: disc;
    margin: 24px 0;
    position: relative;
    display: block;
    padding: 0px;
    gap: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0
}

.accordion-body ul li {
    font-family: 'NexaWebRegular';
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.accordion-body div {
    padding: 16px 0 0px;
    height: 100% !important;
}

@media (max-width:1920px) {
.accordion-item {
align-content: center;
    color: var(--bs-accordion-color);
    display: inline-block;
    height: 75px;
    width: 100%;
}
.accordion-header {
    font-feature-settings: "pnum" on, "lnum" on;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    color: #1b1b1b;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    height: 74px;
    justify-content: space-between;
    line-height: 140%;
    margin: 0 auto;
    order: 0;
    padding: 0;
    text-transform: lowercase;
}
}
@media (max-width:1536px) {
.accordion-item {
            align-content: center;
        display: inline-block;
        height: auto;
        width: 100%;
}

.accordion-header {
    font-feature-settings: "pnum" on, "lnum" on;
    align-items: center;
    color: #1b1b1b;
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    flex-grow: 0;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    height: 54px;
    justify-content: space-between;
    line-height: 140%;
    margin: 0 auto;
    order: 0;
    padding: 0;
    text-transform: lowercase;
}
}