
@media (min-width:290px) and (max-width:900px) {
.AboutUs__container span.text {
    font-size: 18px !important;
    padding: 0 16px !important;
    margin: 0px 0 0 auto !important;
}
.AboutUs__container {
    width: 100% !important;
    margin: 0 0 24px !important;
}
.AboutUs__container nav.breadcrumbs {
    display: none;
}
.AboutUs {
    padding: 0 !important;
}
}
.AboutUs {
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    padding: 0 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    align-content: center;
    width: 100%;
}

.AboutUs__container {
    height: auto;
    margin: 0 0 24px;
}
nav.breadcrumbs {
    margin: 0 0 40px 0;
}
.AboutUs__image {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}
.AboutUs__container span.text {
font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    display: block;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 200;
    letter-spacing: -.01em;
    line-height: 135%;
    margin: 24px 0 0 auto;
    position: relative;
    width: 100%;
}
