@media screen and (max-width: 375px) {
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
        width: 178px;
    }
    .from-price input#minPrice, .to-price input#maxPrice {
        height: 41px !important;
    }
    input[type=number] {
        height: 41px !important;
    }
}

@media (max-width: 375px) {
    .MuiDialogContent-root .filter-section .slider .price-inputs .to-price label {
        display: block;
        left: 182px !important;
    }
    .show__filter {
        padding: 0 16px 0 0;
        text-align: right;
        width: 50% !important;
    }
    .product__card__action button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
        width: 18px;
        height: 18px;
    }
}
@media (min-width:290px) and (max-width: 1024px) {
	
label.maxPrice {
    position: absolute !important;
    margin-right: 10px !important;
    display: block !important;
    padding-left: 10px !important;
    left: auto !important;
    margin: 12px auto !important;
}
}
@media (min-width:428px) and (max-width: 1024px) {
    .MuiDialogContent-root .filter-section .slider .price-inputs .to-price label {
        display: block;
    }
	    .featured__products .product__image>img {
        height: 100%;
        min-height: 670px;
        object-fit: cover !important;
    }
}
@media (max-width: 414px) {
    .MuiDialogContent-root .filter-section .slider .price-inputs .to-price label {
        display: block;
    }
}
@media (min-width:290px) and (max-width: 414px) {
    .MuiDialogContent-root .filter-section .slider .price-inputs .to-price label {
        display: block;
    }
}
@media (max-width: 430px) {
	.category__product__card {
        gap: 21px;
    }
    .show__filter {
        padding: 0 16px 0 0;
        text-align: right;
    }
    .product__card__action button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
        width: 18px;
        height: 18px;
    }
	.show__filter .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
        border: 0 !important;
        left: 0px !important;
    }
}

@media (max-width: 375px) {
    .MuiDialogContent-root .filter-section .slider .price-inputs .to-price label {
        display: block;
        left: 182px !important;
    }
}
@media (min-width: 290px) and (max-width: 440px) {
    .MuiDialogContent-root {
        padding: 64px 24px 0 16px !important;
    }
    .show__filter {
        padding: 0 16px 0 0;
        text-align: right;
        width: 46% !important;
    }
    .filter-section h3 {
        flex: none;
        flex-grow: 0;
        margin: 0 !important;
        height: 40px !important;
        display: flex;
        align-content: center;
        align-items: center;
    }
    .filter-section svg {
        padding: 1px;
        position: relative;
        top: 0px !important;
    }
    ul.MuiList-root.MuiList-padding.MuiMenu-list {
        float: right;
        list-style: none;
        margin: 0;
        outline: 0;
        top: 0;
        padding: 8px 0;
        position: relative;
        right: 0;
        width: 100% !important;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
        right: 0px !important;
        box-shadow: none !important;
    }
    .filter-section h3, .product__link {
        font-feature-settings: "pnum" on, "lnum" on;
        color: #1b1b1b;
        font-family: 'NexaWebRegular';
        font-size: 24px !important;
    }
    .filter-section div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    .filter-section {
        height: auto;
        margin: 0 auto;
        max-height: 100%;
        margin-bottom: 16px;
        min-height: 40px;
        align-items: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
    li.MuiListItem-root.MuiListItem-gutters div {
        margin: 16px 0 0;
    }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 0px;
        margin-top: 40px;
        gap: 10px;
        text-align: center;
        height: 48px;
        background: #1B1B1B;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        align-content: center;
        flex-wrap: nowrap;
    }
    .MuiDialogActions-root.MuiDialogActions-spacing button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root {
        margin: 0 auto;
        height: 19px;
        font-family: 'NexaWebRegular';
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 135%;
        text-align: center;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .category__card__container, .category__sort {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        height: 40px;
        justify-content: space-between;
        width: 100%;
        margin: 16px 0  0 auto;
    }
    .category__name {
        width: 100%;
        font-size: 40px !important;
    }
    .category__card__card {
        display: block;
        margin: 0 0 auto !important;
        padding-right: 0;
        width: 100% !important;
    }
    .MuiDialogContent-root .filter-section .slider {
        margin: 0 auto;
        width: 100%;
    }
    .MuiDialogContent-root .filter-section .slider .price-inputs .to-price label {
        display: block;
    }
    .MuiDialogContent-root .filter-section .slider .price-inputs .from-price label {
        left: 25px;
        margin: 13px 0 13px;
    }
    .MuiDialogContent-root .filter-section .slider {
        margin: 0px auto;
        width: 100%;
        position: relative;
        top: 0px;
    }
    .filter-section .MuiFormGroup-root {
        top: 4px;
        position: relative;
    }
    .filter-section svg {
        right: 0;
        color: #1b1b1b !important;
        fill: #1b1b1b !important;
    }
    span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-checked.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium svg path {
        fill: #10059F !important;
    }
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium path {
        color: #10059F;
        padding: 0 !important;
        border-width: 1px;
        box-shadow: none;
        border-radius: 0 !important;
    }
    .MuiFormControl-root.MuiFormControl-fullWidth {
        width: 100% !important;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
        width: 200px !important;
        right: 0 !important;
        float: right !important;
        left: 0 !important;
        box-shadow: none !important;
        position: relative !important;
        top: 140px !important;
    }
    li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root {
        background: #fff0;
        color: #1b1b1b;
    }
    li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
        color: #bbb;
    }
    .filter-section h3, .product__link {
        font-feature-settings: "pnum" on, "lnum" on;
        color: #1b1b1b;
        font-family: 'NexaWebRegular';
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        order: 0;
        text-transform: lowercase;
    }
}

.category__container {
    height: auto;
    width: 100%;
    margin: 0px auto;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
    box-shadow: none !important;
}

.featured__categories__card__container .slick-slider .slick-list .slick-track .slick-slide {
    min-width: 100% !important;
}

.category__card, .category__image a {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    grid-gap: 0;
    gap: 0;
    align-content: center !important;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
}

.category {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.category__header__container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.category__header__big {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 16px;
    width: 100%;
}

.category__header {
    align-self: flex-start;
}

.category__header__line {
    width: 120px;
    height: 6px;
    background-color: #FFE26E;
    align-self: flex-start;
    border-radius: 25px;
}

.category__sort {
    width: 30%;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: flex-end;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    color: #1b1b1b;
    width: 44px;
    height: 25px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

span.MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
    display: none;
}

.filters {
    grid-gap: 40px;
    align-content: center;
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;
    height: 75px;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    z-index: 9;
}

.filter-section {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    margin-right: 0;
    top: 0;
    width: auto;
    position: relative;
}

span.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb::before {
    position: absolute;
    content: "";
    border-radius: inherit;
    width: 100%;
    height: 100%;
    box-shadow: none;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border: 0;
}

.Mui-selected:hover {
    background-color: rgb(25 118 210 / 0%);
    color: #bbb;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root:hover {
    color: #1b1b1b;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    font-family: 'NexaWebRegular';
    gap: 10px;
    height: 38px;
    justify-content: flex-start;
    order: 0;
    padding: 0 16px;
    text-transform: lowercase;
    width: 100%;
    color: #bbb;
}

.product__link {
    display: block;
    height: 100%;
    position: relative;
}

.filter-section h3 {
    height: 18px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.category__card__container, .category__sort {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    height: 73px;
    justify-content: center;
    width: 100%;
}

.category__card__container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category__product__card {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-start;
    width: 362px;
}

.show__more__action {
    width: 100%;
    height: auto;
    bottom: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show__more__button {
    width: 200px;
    height: 25%;
    border-radius: 20px;
    font-size: 1rem;
    background-color: #FFE26E;
    border-color: #FFE26E;
    border-style: dashed;
    font-weight: bold;
}

.show__more__action button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    width: 100%;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -.02em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #10059f !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0;
    margin: 24px 0 0px;
    z-index: 9;
    background: none;
    border-radius: 0;
}

.category__header h2 {
    position: relative;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -.01em;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    top: 0;
    margin: 0;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
    font-size: 18px;
    text-align: right;
    width: auto;
    padding-right: 22px !important;
    text-transform: lowercase;
    height: 25px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    background: #fff0;
    border: none;
    display: flex;
    justify-content: flex-start;
    width: 208px;
    height: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    font-family: 'NexaWebRegular';
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    background: #fff0;
    border: none;
    color: #fff !important;
    display: flex;
    justify-content: center;
    width: 208px;
}

.show__filter button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    border: none;
    width: 100%;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -.02em;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0;
    margin: 12px 0;
    z-index: 9;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    gap: 16px;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root svg {
    padding: 1px;
}
.filter-section svg {
    padding: 1px;
    top: 0px;
    position: relative;
}
.Mui-checked {
    color: #10059f !important;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root {
    margin: 0;
    padding: 0px 16px 0 0;
    height: 19px;
    position: relative;
}
.MuiFormGroup-root {
grid-gap: 8px;
    align-content: flex-start;
    align-items: center;
    background: #fff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    height: auto;
    justify-content: center;
	font-family:'NexaWebRegular';
    left: 0;
    padding: 16px;
    position: absolute;
    top: 53px;
    width: 208px;
    z-index: 999;
}
label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    padding: 0px;
    width: 100%;
    height: 25px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.filter-section .slider {
    align-items: flex-start;
    background: #fff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: NexaWebRegular;
    gap: 8px;
    height: 110px;
    left: 0;
    padding: 16px;
    position: absolute;
    top: 50px;
    width: 278px;
    justify-content: center;

}
.price-inputs {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    height: 41px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.from-price input#minPrice, .to-price input#maxPrice {
    height: 54px;
    padding: 12px 5px 12px 33px;
    text-align: left;
    width: 112px;
	font-family:'NexaWebRegular';
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    flex-grow: 1;
}
.price-inputs {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    height: 54px;
	font-family:'NexaWebRegular';
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 16px;
}
span.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root {
    border-radius: 0px;
    box-sizing: content-box;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    color: rgb(25, 118, 210);
    -webkit-tap-highlight-color: transparent;
    height: 4px;
    left: 6px;
    width: 94%;
    padding: 13px 0px;
}
.from-price label{
    z-index: 2;
    display: block;
    margin: 10px 0 5px;
    position: absolute;
    left: 24px;
    top: 16px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.to-price label {
    z-index: 2;
    display: block;
    margin: 11px 10px 10px;
    position: absolute;
    top: 16px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 0;
    flex-grow: 0;
}
input#minPrice, input#maxPrice {
    padding: 0 29px;
    position: relative;
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    flex-grow: 1;
    width: 100%;
    height: 41px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border: 1px solid #bbb !important;
}
span.MuiSlider-rail {
    color: #DFDFDF;
}
span.MuiSlider-track {
    color: #10059f;
}
ul.MuiList-root.MuiList-padding.MuiMenu-list {
float: right;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 8px 0;
    position: relative;
    width: 208px;
}
@media (max-width:1920px) {
    .category__card__container .category__product__card .product__card__card {
        margin: 10px 0px;
        width: 24.499999%;
    }

	    .category__card__container {
        margin-bottom: 104px;
    }
	.category__product__card {
display: flex;
        flex-wrap: wrap;
        gap: 20px 6px;
        height: auto;
        justify-content: flex-start;
        width: 100%;
}
.product__card {
    height: auto;
    width: 100%;
}
a.product__link {
    height: 100%;
}
.product__image>img {
        height: 100%;
        min-height: 380px;
        object-fit: cover !important;
    } 
.show__filter {
width: 208px;
    text-align: right;
    padding: 0 16px 0 0;
}
    .category__card__container, .category__sort {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        height: auto;
        justify-content: space-between;
        width: 100%;
    }
.shop__category__product__card .product__card__card {
    width: 24.277778% !important;
}

	.slick-list, .slick-slider {
    display: block;
    position: relative;
    width: 100%;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root svg {
    padding: 0px !important;
}

}
@media (max-width:1707px) {
	.product__price {
    font-family: 'NexaWebRegular';
    width: 100% !important;
    height: 25px !important;
    font-size: 16px !important;
} 

.show__filter {
        padding: 0 16px 0 0;
        text-align: right;
        width: auto;
}
.MuiBox-root.css-1gtfl7l {
    width: auto;
}

.category__card__container, .category__sort {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    height: auto;
    justify-content: space-between;
    width: 100%;
}
.shop__category__product__card .product__card__card {
        width: 5% !important;
    }


}
@media (max-width:1536px) {
	
    .product__card__card .product__link .product__image>img {
        height: 100%;
        min-height: 261.75px;
        object-fit: cover !important;
    }
	
	.product__price {
    font-family: 'NexaWebRegular';
    width: 100% !important;
    height: 25px !important;
    font-size: 16px !important;
} 
.shop__category__product__card .product__card__card {
        width: 16.4888889% !important;
    }
.show__filter {
    text-align: right;
    padding: 0
}
    .category__card__container, .category__sort {
align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        justify-content: space-between;
        width: 100%;
    }
.shop__category__product__card .product__card__card {
        width: 24.499999% !important;
    }
    a.product__link {
        max-width: 100%;
    }
.category__card__container {
    margin-bottom: 24px;
}


	.show__filter button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
	font-size: 16px !important;	
	}
	.filter-section h3, .product__link, .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall, button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    font-family: 'NexaWebRegular';
    font-size: 16px !important;
	}
}
@media (max-width:1440px) {

.featured__products	
    .product__image>img {
        height: 100%;
        min-height: 232.75px;
        object-fit: cover !important;
    }
.new__products .product__image>img {
        height: 100%;
        min-height: 231.75px;
        object-fit: cover !important;
    }
	.category__product__card {
display: flex;
        flex-wrap: wrap;
        gap: 20px 4px;
        height: auto;
        justify-content: flex-start;
        width: 100%;
}
a.product__link {
        width: 100% !important;
        max-width: 100% !important;
    }
.category__card, .category__image a {
    grid-gap: 0;
    align-content: center !important;
    align-items: center !important;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    gap: 0;
    justify-content: flex-start;
    width: 100% !important;
}
	.product__price, .product__name {
    font-family: 'NexaWebRegular';
    width: 100% !important;
    height: 25px !important;
    font-size: 15px !important;
} 
.related__products .product__card__card {
        width: 100%;
    }

	.filter-section h3, .product__link {
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: 'NexaWebRegular';
    font-size: 16px !important;
    margin:0 auto;
	} 
}

@media (max-width:375px) {
	
.product__card {
    width: 100% !important;

}

    .shop__category__product__card .product__card__card {
        width: 22.666% !important;
    }
	.product__image>img {
	    height: 185px !important;		
	}
	.shop__category__product__card .product__card__card {
        margin: 0;
        width: 100% !important;
    }

.featured__products .slick-slider.slick-initialized {
    margin: 24px 0 40px 0;
}

	.d-flex.min-vh-100.w-100.justify-content-center.align-items-center.m-auto {
        margin: 0px auto !important;
    }
}

@media (max-width:1024px) {
	.category__product__card {
        display: flex;
        flex-wrap: wrap;
        gap: 6%;
        height: auto;
        justify-content: flex-start;
        width: 100%;
    }
	.category__card__container, .category__sort {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        height: auto !important;
        justify-content: space-between;
        width: 100%;
        margin: 20px auto;
    }
	.new__products .product__image>img {
        height: 100%;
        min-height: 670px;
        object-fit: cover !important;
    }
    .product__card__card .product__link .product__image>img {
        height: 100%;
        min-height:100%;
        object-fit: contain !important;
    }
	.MuiFormControl-root.MuiFormControl-fullWidth {
        width: 100% !important;
        align-items: flex-end !important;
    }
	    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
        top: 133px!important;
    } 
.featured__products .product__card__card, .featured__products .slick-list {
        width: 100% !important;
    }
    .featured__products .slick-list .slick-track .slick-slide.slick-active.slick-current {
        min-width: 100%;
    }
	.d-flex.min-vh-100.w-100.justify-content-center.align-items-center.m-auto {
        margin: 0px auto !important;
    }
	    .shop__category__product__card .product__card__card {
        width: 100% !important;
    }    
	.shop__category__product__card .product__card__card .product__card .product__image {
        width: 100%  !important;
    }

.show__filter .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
        border: 0 !important;
        left: -44px;
    }
a.product__link {
        max-width: 24%  !important;
    }
	    .product__name, .product__price {
        font-family: NexaWebRegular;
        font-size: 16px !important;
        width:100%  !important;
    }
}

@media (max-width: 1024px) {
	
	.category__sort.desc {
    display: flex!important;
}
.category__container {
    height: auto;
    margin: 0 auto;
    width: 100%;
    min-height: 65vh;
}
}
@media (max-width: 820px) {
	
	.category__sort.desc {
    display: none!important;
}
.category__container {
    height: auto;
    margin: 0 auto;
    width: 100%;
    min-height: 59vh;
}
}
@media (max-width: 810px) {
	
	.category__sort.desc {
    display: none!important;
}
.category__container {
    height: auto;
    margin: 0 auto;
    width: 100%;
    min-height: 54vh;
}
}
@media (max-width: 768px) {
.category__sort.desc { 
    display: none!important;
}
.category__container {
    height: auto;
    margin: 0 auto;
    width: 100%;
    min-height: 54vh;
}	
}

@media (max-width:440px) {
	.category__sort.desc {
    display: none!important;
}
	.product__card__card .product__link .product__image>img {
        height: 100%;
        min-height: 261.75px !important;
        object-fit: cover !important;
    }
	    .slick-list, .slick-slider {
    display: block;
    height: 100% !important;
    overflow: hidden;
    position: relative;
    }
    .featured__products .slick-list .slick-track .slick-slide.slick-active.slick-current {
        min-width: 100%;
    }
	    .shop__category__product__card .product__card__card .product__card .product__image {
        width: 100%!important;
    }
    .product__image>img {
        object-fit: contain !important;
        width: 100% !important;
		min-height: 100%;
    }
a.product__link {
        max-width: 100%!important;
         width: 100%;
    }
	    .featured__products .product__card__card, .featured__products .slick-list {
        width: 100% !important;
    }
    .featured__products .slick-list .slick-track, .featured__products .slick-list .slick-track .slick-slide {
        gap: 1px !important;
        width: 100% !important;
    }
	.product__name, .product__price {
        font-family: NexaWebRegular;
        font-size:.78em!important;
        width: 100% !important;
		line-height: 80%;
    }
	    .shop__category__product__card .product__card__card {
        width: 49% !important;
        margin-bottom: 8px;
    }
	
	.css-fvipm8 {
    justify-content: flex-end !important;
	}
	    .shop__category__product__card .product__card__card .product__card .product__image {
        width: 100%;
	min-height: 268px !important;
    }
	.product__card {
    width: 98%;
}
}

@media (max-width:390px) {
	    .shop__category__product__card .product__card__card {
        width: 100% !important;
    }    .shop__category__product__card .product__card__card .product__card .product__image {
        width: 100%;
    }
}


@media (max-width:375px) {
.featured__products .d-flex .slick-slider .slick-list .slick-track {
        grid-gap: 13px !important;
        gap: 13px !important;
    }
	    .shop__category__product__card .product__card__card {
        width: 100% !important;
    }    .shop__category__product__card .product__card__card .product__card .product__image {
        width: 100%;
    }
	.overlay {
    padding: 0 16px;
}
.category__image {
    right: 21px !important;
    padding: 0 16px !important;
}
.slick-dots {
    bottom: -162px !important;
}
}
