@media (min-width:290px) and (max-width:1024px) {

.landing__image__container {
        align-content: center !important;
        align-items: center !important;
        display: flex;
        flex-direction: column !important;
        height: 465px !important;
        justify-content: center !important;
        width: 100%;
    }
.landing__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-height: 465px !important;
    min-height: 465px !important;
    position: relative;
    width: 100%;
}
.landing__image {
    display: block;
    width: 100%;
    height:500px; /* или любая другая высота */
    background-size: cover;
    background-position: center;
}
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
    background-color: #fff0 !important;
}

.landing__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-height: 710px;
    min-height: 710px;
    position: relative;
    width: 100%;
}

.h1, h1 {
    font-size: 24px;
    font-family: NexaWebRegular;
    font-weight: 400;
}
.landing__header__main {
    font-size: 4.0rem;
    font-weight: 800;
}

.landing__header__discount {
    font-size: 1.2rem;
}

.landing__button {
    width: 60%;
    cursor: pointer;
    transition: .3s;
}

.landing__image__container {
    width: 100%;
    height: 710px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 2560px) {
	
	.landing__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-height: 100dvh;
    min-height: 100dvh ;
    position: relative;
    width: 100%;
}
.landing__image__container {
    align-items: flex-start;
    background-position: 50%;
    background-size: cover;
    display: flex;
    height: 100dvh ;
    justify-content: center;
    width: 100%;
    align-content: flex-start;
}
.landing__image {
    display: block;
    width: 100%;
    height: 100dvh; /* или любая другая высота */
    background-size: cover;
    background-position: center;
}
}
@media (max-width: 1920px) {

	.landing__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-height: 100dvh ;
    min-height: 100dvh;
    position: relative;
    width: 100%;
}
.landing__image__container {
    align-items: flex-start;
    background-position: 50%;
    background-size: cover;
    display: flex;
    height: 100dvh;
    justify-content: center;
    width: 100%;
    align-content: flex-start;
}
.landing__image {
    display: block;
    width: 100%;
    height: 100dvh; /* или любая другая высота */
    background-size: cover;
    background-position: center;
}
.overlays {
    width: 100%;
    position: fixed;
    background:#2020203b !important;
    height: 100%;
    z-index: -1;
    left: 0;
    bottom: 0;
}


button.Mu.overlay {
    width: 100%;
    position: fixed;
    background: #000000b0;
    height: 100%;
    z-index: -1;
    left: 0;
    bottom: 0;
}

iButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
        display: flex;
        float: right;
    }
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    background-color: #fff0 !important;
}
    .search-popup-close.desc {
        position: absolute;
        right:150px;
        top: 24px;
        z-index: 9;
    }
}
@media (max-width: 1536px) {
    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
        display: inline-block;
        float: right;
        height: 18px;
        padding: 0;
        position: relative;
        right: 0;
        margin: 0 0 6px 0;
        transition: transform .4s ease;
        vertical-align: top;
        width: 18px;
    }
	.landing__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-height: 100dvh ;
    min-height: 100dvh;
    position: relative;
    width: 100%;
}
.landing__image__container {
    align-items: flex-start;
    background-position: 50%;
    background-size: cover;
    display: flex;
    height: 100dvh;
    justify-content: center;
    width: 100%;
    align-content: flex-start;
}
.landing__image {
    display: block;
    width: 100%;
    height: 100dvh; /* или любая другая высота */
    background-size: cover;
    background-position: center;
}
}
@media (max-width: 1440px) {
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
        background-color: #fff0 !important;
        display: flex;
    }
	.landing__container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-height: 740px;
    min-height: 740px;
    position: relative;
    width: 100%;
}
.landing__image__container {
    align-items: flex-start;
    background-position: 50%;
    background-size: cover;
    display: flex;
    height: 740px;
    justify-content: center;
    width: 100%;
    align-content: flex-start;
}
.landing__image {
    display: block;
    width: 100%;
    height: 740px; /* или любая другая высота */
    background-size: cover;
    background-position: center;
}
}

@media (max-width: 1024px) {

.landing__container {
    max-width: 100% !important;
}
.landing__image {
    display: block;
    width: 100%;
    height: 465px; /* или любая другая высота */
    background-size: cover;
    background-position: center;
}
}
@media (max-width: 440px) {
	.landing__image {
        background-position: center;
        background-size: content;
        display: block;
        height: 500px !important;
        width: 100%;
    }
.landing__container {
        height: 465px !important;
        max-width: 100% !important;
    }
	    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
        display: inline-block;
        float: right;
        height: 18px;
        padding: 0;
        position: relative;
        right: 0;
        margin: 0 0 6px 0;
        transition: transform .4s ease;
        vertical-align: top;
        width: 18px;
    }
}