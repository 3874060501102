
@media (min-width: 430px) and (max-width: 1024px) {
    .related__products {
        margin: 0 !important;
        padding: 0 16px;
    }
}
@media (min-width: 394px) and (max-width: 430px) {
.item__container .related__items__container {
    margin: 40px 0 0;
	padding: 0 16px;
}
.related__product__description, .related__product__name, .related__product__price {
    font-family: NexaWebRegular;
    font-size: 16px !important;
}
.item__container .related__items__container {
    padding: 0 16px;
    margin: 0px 0 40px !important;
}	
    .slick-track {
        grid-gap: 24px;
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
        justify-content: center;
        width: 100% !important;
    }
}
@media (min-width: 290px) and (max-width: 375px) {
.item__container .related__items__container {
    margin: 40px 0 0;
	padding: 0 16px;
}
.related__product__description, .related__product__name, .related__product__price {
    font-family: NexaWebRegular;
    font-size: 16px !important;
}
.slick-track {
        grid-gap: 24px;
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px !important;
        justify-content: space-between;
        width: 100% !important;
    }
}
@media (min-width: 376px) and (max-width: 393px) {
.item__container .related__items__container {
    margin: 40px 0 0;
	padding: 0 16px;
}
.related__product__description, .related__product__name, .related__product__price {
    font-family: NexaWebRegular;
    font-size: 16px !important;
}
.slick-dots {
    bottom: 42px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
}
.featured__categories .carousel-indicators {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.featured__categories .slick-track {
        grid-gap: 13px;
        display: flex;
        gap: 13px;
        justify-content: flex-start !important;
        width: 100% !important;
        flex-direction: row !important;
        align-content: flex-start !important;
        align-items: flex-start !important;
    }
	    .related__items__container .related__products .related__card__container .carousel .carousel__item {
        flex: 0 0 auto;
        margin: 24px 0px 24px 0px !important;
        min-height: 320px;
        min-width: 16.5%;
        width: 100%;
    }
	.related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image>img {
        height: 234px !important;
        width: 100% !important;
    }
    .related__card__container .carousel .carousel__item .related__product__card__container {
        height: 259px !important;
        margin: 0;
        min-width: 100% !important;
        margin: 24px auto;
    }
	.related__products {
    margin: 40px 0 0 !important;
}
	.related__card__container .carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image, .related__items__container .related__products .related__card__container .carousel .carousel__item {
        height: 234px !important;
    }
	.carousel {
        grid-gap: 24px !important;
        align-content: flex-start;
        align-items: flex-start;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        gap: 30px !important;
        height: auto !important;
        justify-content: center !important;
        overflow-x: auto !important;
        scroll-behavior: smooth !important;
	}
	.related__items__container {
    height: auto;
    justify-content: space-between;
    margin-bottom: 40px !important;
	}
}
@media (min-width:440px) and (max-width:1024px) {
    a.product__link {
        max-width: 342px;
    }
.related__product__description, .related__product__name, .related__product__price {
    font-family: NexaWebRegular;
    font-size: 16px !important;
}
.carousel-indicators {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.slick-track {
        grid-gap: 28px;
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
        justify-content: flex-start;
        width: 100% !important;
    }
    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg {
        height: 18px !important;
        min-width: 18px !important;
        width: 18px !important;
    }
.featured__products .slick-list .slick-track .slick-slide.slick-active, .featured__products .slick-list .slick-track .slick-slide.slick-active.slick-current {
        max-width: 50% !important;
        min-width: 50% !important;
    }
}
  
@media (max-width:1920px) {
	  .featured__products .d-flex .slick-slider .slick-list .slick-track {
-webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        cursor: inherit;
        gap:15.555557px;
        justify-content: flex-start;
        pointer-events: auto;
    }
.featured__products .slick-list .slick-track {
    width: 100%;
}
.related__items__container .related__products .related__card__container .carousel .carousel__item {
        flex: 0 0 auto;
        margin: 0;
        width: 24.499999%!important;
        min-width: 24.499999%!important;
    }
	a.related__product__link {
    height: 100%;
    display: block;
}
.related__products {
    margin: 0px 0 0 !important;
}
.carousel {
    gap: 6px !important;
}
.carousel__item {
    margin-top: 0px !important;
}
.related__header__container {
    margin: auto;
    align-items: center !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-content: center !important;
    flex-wrap: nowrap !important;
}
.related__items__container {
    margin: 0px 0 40px;
}
.related__card__container {
        margin: 0 !important;
        padding: 0 !important;
}
.carousel-indicators {
        display: none !important;
        align-items: center;
        justify-content: center;
        align-content: center;
		gap: 0px;
    }
    .related__items__container {
        height: auto;
        margin-bottom: 0px !important;
        width: 100%;
        padding: 0 16px;
    }


    .related__card__container .carousel {
        height: 100% !important;
        width: 100%;
    }
.related__items__container {
    height: auto;
    width: 100%;
    margin-bottom: 104px;    
    justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
}
.related__products__container {
    height: auto;
    width: 100%;
    margin: 10px auto;
}

.related__products {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
	margin: 0 auto;
}

.item__container .related__items__container {
    margin: 0px 0 0;
	padding: 0px;
}
.related__header__container {
    height:auto;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.related__header {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-content: center;
}
.related__header__line {
    width: 180px;
    height: 6px;
    background-color: #FFE26E;
    align-self: center;
    border-radius: 25px;
}

.related__card__container {
    height: auto;
    width: 100%;
	margin:24px 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.related__product__card {
    height: auto;
    width: 95%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.product__carousel .carousel {
    align-content: center;
    display: block;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    padding: 0;
}

.related__card__container {
    scrollbar-width: none; /* Удалить полосу прокрутки на Firefox */
}

.carousel::-webkit-scrollbar {
    display: none; /* Удалить полосу прокрутки на Chrome и Safari */
}
.delivery-name p {
    margin-top: 16px;
}

		    .featured__products .slick-list .slick-track .slick-slide.slick-active, .featured__products .slick-list .slick-track .slick-slide.slick-active.slick-current {
        max-width: 100%;
    }
	.related__product__card__container {
    margin: 0;
    min-width: 100% ;
}
    .related__product__card__container {
        height: 100%;
        min-width: 100% ;
        margin: 0;
    }
.carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image>img {
        width: 100%;
    }
.custom-carousel-item.active.carousel-item .carousel__image__container {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
.related__header h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 115%;
    letter-spacing: -.01em;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    order: 0;
    flex-grow: 0;
    margin: 0;
}
.carousel__item {
    flex: 0 0 auto;
    min-width: 100%;
    margin-top: 24px;
}
}
@media (max-width:1440px) {
		  .featured__products .d-flex .slick-slider .slick-list .slick-track {
        grid-gap: 21px !important;
        gap: 21px !important; 
    }    
	.carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image>img {
        width: 100%;
    }
	.related__items__container .related__products .related__card__container .carousel .carousel__item {
        margin: 0 !important;
    }
	.related__product__card__container {
        margin: 0;
        min-width:100% !important;
    }
	.related__product__description, .related__product__name, .related__product__price {
    font-size: 16px;
}
}
@media (max-width:1536px) {
	.related__product__description, .related__product__name, .related__product__price {
    font-size: 16px !important;
}
.related__product__card__container {
        margin: 0;
        min-width: 100% !important;
        min-width: 100% !important;
    }
.carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image>img {
object-fit: contain !important;
        width: 100%;
    }
.custom-carousel-item.active.carousel-item .carousel__image__container {
        display: block;
        height: 100%;
        width: 100%;        
		overflow: hidden;
}
.detail__and__carousel__container .product__carousel__container .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
}
.related__header h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 115%;
    letter-spacing: -.01em;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    order: 0;
    flex-grow: 0;
    margin: 0;
}
    .related__product__image, a.related__product__link {
        display: block;
        width: 100%;
    }

.carousel__item {
    flex: 0 0 auto;
    min-width: 100%;
    margin-top: 24px;
}
}

@media (max-width:375px) {    
.related__product__card__container {
        margin: 0;
        min-width: 100% !important;
    }
	  .featured__products .d-flex .slick-slider .slick-list .slick-track {
        grid-gap: 10px !important;
        gap:  10px !important;
    }

}
@media (max-width:440px) {
	.carousel .carousel__item .related__product__card__container .related__product__card__inner .related__product__image>img {
    width: 100% !important;
}


    .featured__products .slick-list .slick-track .slick-slide.slick-active, .featured__products .slick-list .slick-track .slick-slide.slick-active.slick-current {
        height: 100% !important;
        max-width: 50% !important;
        min-width: 50% !important;
    }
.related__items__container {
    margin-bottom: 0px !important;
    padding: 0 16px !important;
}
.item__container .related__items__container {
    margin: 40px 0 0;
	padding: 0 16px !important;
}
.detail__and__carousel__container .product__carousel__container .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container {
        display: block;
        width: 100% !important;
    }
	.related__card__container {
    scrollbar-width: none;
    margin-top: 40px;
}
    .detail__and__carousel__container .product__carousel__container .carousel.carousel-dark .carousel-inner .custom-carousel-item.active.carousel-item .carousel__image__container {
        display: block;
        width: 100% !important;
    }
	.item__container .related__items__container {
    margin: 0;
    padding: 0 16px;
}
.related__items__container .related__products .related__card__container .carousel .carousel__item {
        flex: 0 0 auto;
        margin-top: 30px;
        max-width: 50% !important;
        min-width: 50% !important;
        margin: 0px !important;
    }
    .related__items__container .related__products .related__card__container .carousel {
        gap:4px !important;
        justify-content: flex-start;
    }
}