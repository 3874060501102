

.nav__bottom__container a:hover {
    color: #bbb;
}
.nav__bottom__container a {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: lowercase;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
        color: #1b1b1b;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    list-style: none;
}

.nav__bottom__container {
    height: 75px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
}

.bottom__container {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row
}

.container .nav-link {
    color: black;
}

.control__small {
    width: 50%;
    height: auto;
    display: none;
}

@media screen and (max-width: 1024px) {
    .nav__bottom__container{
        display: none;
    }
}

@media screen and (max-width: 1536px) {
    .control__small{
       display: flex;
       margin: auto 10px;
    }
}

@media screen and (max-width: 1920px) {
.submenu-content {
    list-style: none;
    width: 100%;
    background: #fff;
    position: fixed;
    left: 0;
    top: 75px;
    right: 0;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    transform: translateY(-20px);
    opacity: 0;
    display: none;
}
}
.submenu-content {
    display: none; 
}
.submenu-content.show {
	align-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    opacity: 1;
    padding: 40px 200px;
    transform: translateY(0);
    transition: transform .3s ease, opacity .3s ease;
}

.submenu-content:not(.show) {
    transform: translateY(-20px); 
    opacity: 0;
}

.left-column, .right-column {
    padding:0;
}
li.nav-link.inactive a, li.nav-link.inactive span {
    color: #bbb !important;
} 
.right-column ul.sub-menu-right {
    padding: 0;
}
.nav-link span{
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: lowercase;
	font-family: 'NexaWebRegular';
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
}
.nav-link {
    list-style: none;
    padding: 0 22px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.nav-item {
    color: #1b1b1b;
    text-decoration: none;
}

.nav-item:hover {
    color: #bbb;
}
.submenu-columns {
    margin: 0 auto;
}
ul.sub-menu-left {
    padding: 0;
}
.submenu-columns, .submenu-images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 0;
    width: auto;
    align-items: center;
    align-content: center;
}
.submenu-images {
    align-content: center;
    align-items: center;
    gap: 10px;
}
button.search-button {
    border: none;
    background: none;
}
@media (max-width: 1920px) {
ul.sub-menu-left li, ul.sub-menu-right li {
    width: 233px;
    height: 25px;
    margin: 0px 0px 8px 0px;
    list-style: none;
}
.nav__bottom__container a {
    font-size: 18px;
}
.submenu-content.show {
    padding: 40px 200px 40px;
}
a.collection-link {
    text-transform: uppercase;
}
.submenu-images a img {     
        height: 227px !important;
        width: 100% !important;

}
}

@media (max-width: 1536px) {
ul.sub-menu-left li, ul.sub-menu-right li {
    width: 233px;
    height: 22px;
    margin: 0px 0px 8px 0px;
    list-style: none;
}
.nav__bottom__container a {
        font-size: 16px;
        color: #1b1b1b;
}
.submenu-content.show {
    padding: 40px 200px 40px;
}
a.collection-link {
    text-transform: uppercase;
}
.submenu-images a img {
    height: 172px !important;
}
}

@media (max-width: 1440px) {
	.submenu-content {
        top: 75px !important;
    }
	.submenu-images {
    align-content: center;
    align-items: center;
    gap: 10px;
}
}
@media (max-width: 1228.8px) {
ul.sub-menu-left li, ul.sub-menu-right li {
    width: 200px;
    height: 22px;
    margin: 0px 0px 8px 0px;
    list-style: none;
}
.nav__bottom__container a {
    font-size: 16px;
}
.submenu-content.show {
    padding: 40px 150px 40px;
} 
a.collection-link {
    text-transform: uppercase;
}
.submenu-images a img {
    height: 180px !important;
}
}