
@media (min-width:290px) and (max-width:1024px) {
.new__products__container {
    padding: 0 17px !important;
}
.new__items__header__big {
    font-size: 24px !important;
    margin: 0 !important;
}
.new__products__container {        
    margin-bottom: 40px;
}
.new__products__container {
    width: 100%;
    margin: 0px 0 16px auto!important;
}
.arrow svg, .arrows svg, .item .item-link .arrow svg, .item .item-link .arrows svg {
        width: 24px !important;
        height: 24px;
    }
    .item .item-link .arrows svg, .item .item-link .arrow svg {
        width: 24px !important;
        height: 24px;
    }
.arrow svg, .arrows svg {
    width: 18px !important;
}
button.slick-arrow.slick-prev {
    display: none !important;
    left: 0 !important;
}
.slick-track {
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start !important;
        width: 100% !important;
        grid-gap: 10px;
        gap: 10px;
        align-content: center;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0;
        margin: 0 auto;
    }
}
@media screen and (max-width: 1920px) {
.new__products__container {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
}
.new__products {
align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}
}
@media screen and (max-width: 1536px) {
.new__products__container {
        margin: 0 0 0px auto;
        width: 100%;
    }
}
.new__products__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 28px;
    text-align: center;
    width: 100%;
}

.new__items__header__big {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.01em;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.new__products__card__container {
    margin-top: 43px;
    padding: 0px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    gap: 24px;
    margin-bottom: 104px;
}

.arrow {
    font-size: 24px;
    color: #ffe26e;
    position: relative;
    top: 50%;
    cursor: pointer;
}

.arrows {
    font-size: 24px;
    position: relative;
    top: 50%;
    cursor: pointer;
    gap: 46px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}

.next {
    right: 0px;
}

.prev {
    left: 23px;
}

.slick-track {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
}
.featured__categories .slick-slider .slick-list .slick-track {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0 !important;
}

.slick-slide {
    margin: 0  /* Optional: add spacing between slides */
}

.align-items-center {
    align-items: flex-start !important;
}

.arrow.prev svg path {
    color: #bbb;
    fill: #bbb;
}

.arrow.prev svg path:hover, 
.arrow.next svg path:hover {
    color: #10059f;
    fill: #10059f;
}

.slick-list, .slick-slider {
    position: relative;
    display: block;
    width: 100%;
}