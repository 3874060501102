
@media (max-width: 1920px) {
    .thanks-container {
        padding: 300px 535px;
        text-align: center;
    }

.thanks-container p {
        font-size: 16px !important;
        line-height: 1.4;
        margin: 0px;
    }
.thanks-container h2 {
    font-size: 48px;
}
}
@media (max-width: 768px) {
.thanks-container {
    background: #fff;
    display: block;
    height: 100%;
    margin-top: -100px;
    min-height: 126vh;
    overflow: hidden;
    padding: 350px 16px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 9999;
}

.thanks-container h2 {
    text-transform: lowercase;
    font-size: 20px;   
	margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
.thanks-container p {
        font-size: 11px !important;
        line-height: 1.4;
        margin: 0px;
    }
}
.thanks-container button.closepopups {
    font-feature-settings: "pnum" on, "lnum" on;
    align-content: center;
    align-items: center;
    background: #1b1b1b;
    border: none;
    color: #fff;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    font-family: NexaWebRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    gap: 10px;
    height: 48px;
    justify-content: center;
    line-height: 135%;
    margin: 50px 16px;
    order: 0;
    padding: 0 16px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 91% !important;
}

.thanks-container button.continue-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 48px;
    color: #fff;
    text-align: center;
    background: #1B1B1B;
    align-content: center;
    flex-wrap: nowrap;
    margin: 0 auto;
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    flex: none;
    order: 0;

}
.thanks-container p {
    color: #BBBBBB;
}