
@media (min-width:290px) and (max-width:900px) {
.Offer__container {
        font-family: NexaWebRegular !important;
        font-size: 16px !important;
        font-weight: 400;
        height: auto;
        margin-bottom: 24px !important;
        width: 100%;
    }
.Offer__container {
    height: auto;
    width: 100%;
    margin-bottom: 24px !important;
}
.Offer__container nav.breadcrumbs {
    display: none;
}
.Offer__container h1 {
    margin: 40px 0 24px !important;
}
.Accordion__content p {
    font-size: 16px !important;
}
    .Accordion h1 {
        font-size: 16px !important;
        height: 54px !important;
    }
.Offer {
    height: auto;
    width: 100%;
    padding: 0!important;
}
}
.Offer__container {
        font-family: NexaWebRegular !important;
        font-size: 18px ;
        font-weight: 400;
        height: auto;
        margin-bottom: 24px !important;
        width: 100%;
    }
.Offer {
    height: auto;
    width: 100%;
    padding: 0 200px;
}
.textwidth {
    padding: 0 335px;
}
.Offer {
    min-height: 85vh;
    justify-content: center;
    align-items: flex-start;
    margin:0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    align-content: flex-start;
}
.Offer__container h1 {
font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    height: 80px;
    left: calc(50% - 162.5px);
    line-height: 125%;
    margin: 0 0 24px;
    text-align: center;
    text-transform: lowercase;
}
.Offer__container ol, .Offer__container ul {
    padding-left: 6rem;
}
.Offer__container {
	height: auto;
    width: 100%;
}
nav.breadcrumbs {
    margin: 0 0 40px 0;
}

.Offer__container {
    margin-bottom: 120px;
}

@media (max-width: 1536px) {
.Offer__container h1 {
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    font-family: NexaWebRegular;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    height: 80px;
    left: calc(50% - 162.5px);
    line-height: 125%;
    margin: 0 0 24px;
    text-align: center;
    text-transform: lowercase;
}
.textwidth {
    padding: 0 177px;
}
    .Offer__container {
    font-family: NexaWebRegular !important;
    font-size: 16px !important;
    font-weight: 400;
    margin-bottom: 24px !important;
}
}

@media (max-width: 600px) {
    .Offer {
        padding: 0 16px;
    }
    .textwidth {
        padding: 0 16px;
    }
	    .navbrand__container h1.navbrand svg {
        height: 24px !important;
        left: 0;
        position: relative;
        top: 0;
        width: 125px !important;
    }
	.Offer__container h1 {
        font-feature-settings: "pnum" on, "lnum" on;
        color: #1b1b1b;
        font-family: NexaWebRegular;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        height: 40px;
        left: calc(50% - 162.5px);
        line-height: 125%;
        margin: 0 0 24px;
        text-align: center;
        text-transform: lowercase;
    }
}
