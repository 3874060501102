

.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:70vh;
}

.admin-login-title {
  margin-bottom: 20px;
  color: #1b1b1b;
}

.admin-login form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-login form div {
  margin-bottom: 15px;
}

.admin-login label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.admin-login input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bbb;
  border-radius: 5px;
  font-size: 16px;
}

.admin-login input:focus {
  border-color: #007bff;
  outline: none;
}

.admin-login-button {
  padding: 10px;
  background-color: #1b1b1b;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-login-button:hover {
  background-color: #10059f;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.admin-login-button {
    background: #1b1b1b;
    height: 52px;
    font-family: 'NexaWebRegular';
}

input#password {
    margin: 16px 0 !important;
    border-bottom: 1px solid #1b1b1b;
    border-radius: 0;
}