@media (min-width:290px) and (max-width:1024px) {
    ul.MuiList-root.MuiList-padding.submenu li span {
        font-size: 16px !important;
        height: 40px;
        line-height: 230%;
    }
	li.MuiListItem-root.MuiListItem-gutters {
    margin: 0 0 16px 0;
}
.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.collection-link .MuiListItemText-root span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    text-transform: uppercase;
}
ul.MuiList-root.MuiList-padding.submenu li.MuiListItem-root.MuiListItem-gutters .MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root {
        margin: 0px 0 0 !important;
    }
span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 24px;
    text-transform: lowercase;
    font-family: NexaWebRegular;
    font-weight: 400;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1yxmbwk {
    right: 27px;
    top: 19px;
}
ul.MuiList-root.MuiList-padding {
        top: 46px;
        padding: 0;
}
.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root.css-1uwabd6 svg {
    position: relative;
}
li.MuiListItem-root.MuiListItem-gutters:hover {
    color: #bbb;
}
ul.MuiList-root.MuiList-padding.submenu{
    top: 0;
    padding: 0;
    margin: 0;
}
    li.MuiListItem-root.MuiListItem-gutters {
        padding: 0;
        margin: 0 auto;
    }
	ul.MuiList-root.MuiList-padding.submenu li.MuiListItem-root.MuiListItem-gutters {
        padding: 0;
        margin: 0px auto;
    }
ul.MuiList-root.MuiList-padding.submenu li a div span {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.MuiListItemText-root {
    height: 40px;
   margin: 0 auto !important;
}
.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root {
    height: 40px;
    display: flex;
    align-content: center;
    align-items: center;
            padding: 0 27px 0 16px;
    flex-wrap: nowrap;
}
ul.MuiList-root.MuiList-padding li.MuiListItem-root.MuiListItem-gutters .MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root {
    margin: 16px 0 0;
}
ul.MuiList-root.MuiList-padding ul.MuiList-root.MuiList-padding.submenu li.MuiList-root.MuiList-padding.submenu li.MuiListItem-root.MuiListItem-gutters .MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root {
    margin: 0 auto;
}
}