
@media (min-width: 290px) and (max-width: 440px) {
    .category__card__card {
        width: 100% !important;
    }
    .category__card__detail {
        position: relative !important;
        text-align: center;
        top: 200px;
        left: 0px;
        width: 100% !important;
        z-index: 2;
    }
    .category__name p {
        text-align: center !important;
        width: 100% !important;
    }
    .category__card__detail a .category__name span {
        font-size: 40px;
        text-align: center;
        color: #fff;
        width: 100%;
    }
    .category__card__card .category__card__detail a .category__name p {
        font-size: 16px;
        color: #fff;
    }
    .overlay {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #20202066;
        opacity: 1;
        z-index: 1;
        transition: opacity 0.3s;
    }
    .image-wrapper:hover .overlay {
        opacity: 1;
    }
.category__card__detail {
    bottom: 0;
    color: #1b1b1b;
    flex-direction: row;
    height: 87px;
    margin: 0;
    position: relative;
    width: 100%;
}
}

.category__card__card {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0;
}

.category__card {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category__card, .category__image a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.category__card__detail {
    bottom: 0;
    color: #1b1b1b;
    flex-direction: row;
    height: 87px;
    margin: 0;
    position: relative;
    width: 100%;
}

.category__name span {
    width:100%;
    height: 46px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.category__card__detail a {
    display: block;
}

.category__name {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-family: 'NexaWebRegular';
    font-size: 56px;
    font-weight: 400;
    justify-content: flex-end;
    text-transform: uppercase;
    width: 100%;
    padding: 15px 0 0 0;
}

.category__description {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
}

.category__card__action {
    width: 40%;
    height: 80%;
    bottom: 0%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.category__card__action__button {
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 0.8rem;
    background-color: transparent;
    border-color: #FFE26E;
    border-style: solid;
    font-weight: bold;
    color: #FFE26E;
}

.category__name p {
    height: 25px;
    margin: 0;
    text-align: left;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.category__name span {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
    font-family: 'NexaWebRegular';
    font-style: normal;
    line-height: 25%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}