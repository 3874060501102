/* Общие стили для всех устройств с использованием flexbox и прокрутки */
.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 32, 32, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0;
    overflow-y: auto; /* Добавьте это для вертикальной прокрутки */
}

.popup-content {
    background-color: #fff;
    padding: 88px 0 0;
    border-radius: 0;
    text-align: center;
    top: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    transform: translateY(-200%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    min-height: 35vh;
    overflow-y: auto; /* Добавьте это для вертикальной прокрутки */
}

.popup-content.active {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 1920px) {
ul.search-results li.search-result-item .product-details .product__card__action button {
    width: 24px;
    height: 24px;
}

}
@media (min-width: 290px) and (max-width: 1024px) {
    .nav__top__container .search-popup {
        top: 75px !important;
    }
    .nav__top__container .search-popup .popup-content.active button.search-btn {
        border: 0;
        background: none;
        top: -41px !important;
        display: block;
        position: relative;
        float: right !important;
        right: 0 !important;
    }
    .search-popup {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        background-color: rgba(32, 32, 32, 0.75) !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 1000 !important;
        padding: 0 !important;
        overflow-y: auto; /* Добавьте это для вертикальной прокрутки */
    }
    .popup-content.active {
        padding: 0 16px !important;
        top: 75px !important;
        max-height: 80vh; /* Ограничьте высоту активного контента */
        overflow-y: auto; /* Добавьте это для вертикальной прокрутки */
    }
    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    .search-result-item {
        max-width: 49%; /* Делаем элементы более компактными для мобильных устройств */
        width: 100%;
        margin-bottom: 12px;
    }
    .product__image {
        width: 100%; /* Убедитесь, что изображения масштабируются */
    }
}
.nav__top__container .search-popup {
    top: 0;
    width: 100%;
    position: fixed;
}

.popup-content {
    background-color: #fff;
    padding: 88px 0 0;
    border-radius: 0;
    text-align: center;
    top: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    transform: translateY(-200%);
    transition: opacity .3s ease, transform .3s ease;
    min-height: 35vh;
}
.popup-content.active {
    opacity: 1;
    transform: translateY(0);
}

.popup-content {
    background-color: #fff;
    padding: 88px 0 0 0;
    border-radius: 0;
    text-align: center;
    right: 0;
    top: 0;
    position: fixed;
    width: 100%;
    opacity: 0;
    transform: translateY(-200%);
    transition: opacity .3s ease, transform .3s ease;
    min-height: 35vh;
}
.placeholder-text {
    position: relative;
    height: 22px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    text-align: left;
    margin: 16px 0;
}
.cart__item__card, .search-results {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 86px 0 0 0;
    grid-gap: 11px;
    grid-gap: 0;
    gap: 0;
    width: 100%;
}
.search-popup.desc .popup-content.active {
    padding: 89px 200px 24px 200px;
}
.no-results {
    height: 22px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    display: block;
    text-align: left;
    margin: 16px 0;
}
ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc .product__image>img {
    border: none;
    margin: 0 auto;
    max-width:  100%;
    transition: 1s;
}
ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc .product__image {
    width: 100%;
}
ul.search-results li.search-result-item .product-details h4.product-name {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0;
}
ul.search-results li.search-result-item .product-details p.product-price {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
ul.search-results li.search-result-item .product-details {
    text-align: left;
    display: block;
    padding: 8px 0px;
    width: 100%;
    height: 54px;
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
}
.close-popup {
    position: absolute;
    top: 24px;
    right: 102px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}
ul.search-results li.search-result-item .product-details .product__card__action button {
    border: 0;
    background: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    grid-gap: 10px;
    gap: 10px;
    margin: 12px 0 auto;
    order: 1;
    width: 48px;
    height: 16px;
    font-size: 12px;
    line-height: 135%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #bbb;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.popup-content.active .search-input {
    width: 100%;
    padding: 16px !important;
    font-size: 18px !important;
    border: 1px solid #ccc;
    max-width: 1262px;
    margin-top: 88px;
    margin: 0 auto;
    margin-top: 88px;
    display: flex;
    font-family: 'NexaWebRegular';
    position: relative;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    border: none;
    border-bottom: 1px solid #bbb;
}

button.search-button {
    border: 0;
    background: none;
}

.nav__top__container .search-popup {
    top: 0px;
}
.nav__top__container .search-popup .popup-content.active button.close-popup {
    top: 16px;
    right: 16px;
}
.nav__top__container .search-popup .popup-content.active input[type=text] {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #bbb;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0 auto !important;
    display: block;
    border-bottom: 1px solid #DFDFDF;
}
.nav__top__container .search-popup .popup-content.active button.search-btn {
    border: 0;
    background: none;
    top: -41px !important;
    display: block;
    position: relative;
    right: 138px;
}
@media (max-width: 1920px) {
ul.search-results.desc li.search-result-item.desc {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 24px;
        max-width: 100%;
        padding: 0;
}	

ul.search-results li.search-result-item .product-details .product__card__action button {
    width: 24px;
    height: 24px;
}

ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc .product__image {
    height: 386px;
    width: 100%;
}
ul.search-results.desc {
        align-content: flex-start;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 46px 18px;
        height: 100%;
        justify-content: flex-start;
        margin: 0 auto;
        min-height: 100vh;
        overflow: hidden;
        width: 100%;
}
ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc {
    border-radius: 2px;
    display: block;
    flex: none;
    flex-grow: 0;
    height: 386px;
    order: 1;
    padding: 8px 0;
    text-align: left;
    width:100%;
    z-index: 1;
}
    .search-popup.desc .popup-content.active {
        overflow: auto;
        padding: 88px 200px 24px;
        height: 800px;
    }

}
@media (max-width: 1536px) {

    ul.search-results.desc li.search-result-item.desc {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 80px;
        max-width: 100%;
        padding: 0;
        width: 24.4444449%;
    }
ul.search-results.desc li.search-result-item.desc .product__image>img {
        height: 100%;
        min-height: 388px;
        object-fit: cover !important;
    }
 ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc .product__image {
        height:387px;
        width: 100%;
    }
	    ul.search-results li.search-result-item {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 12px;
        max-height: 408px;
        max-width: 196px;
        padding: 0;
    }
    ul.search-results.desc {
        align-content: flex-start;
        align-items: center;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 22px 5px;
        height: 417px;
        justify-content: flex-start;
        margin: 0 auto 24px;
        overflow: scroll;
        position: relative;
        top: 0;
        width: 100%;
    }
	.nav__top__container .search-popup.desc .popup-content.active button.search-btn {
    background: none;
    border: 0;
    display: block;
    position: relative;
    right: 0px;
    top: -41px !important;
}
.search-popup-close.desc {
        position: absolute;
        right: 193px;
        top: 24px;
        z-index: 9;
}
    ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc {
        border-radius: 2px;
        display: block;
        flex: none;
        flex-grow: 0;
        height:449px;
        order: 1;
        padding: 8px 0;
        text-align: left;
        width: 100%;
        z-index: 1;
    }
ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc .product__image>img {
    border: none;
    margin: 0 auto;
    max-width: 100%;
    transition: 1s;
}
.search-popup.desc .popup-content.active {
    padding: 88px 289px 24px;
}
  
ul.search-results li.search-result-item .product-image {
    object-fit: cover;
    width: 100%;
}
    li.search-result-item.desc a.product__link .product-details.desc .product__name {
        font-feature-settings: "pnum" on, "lnum" on;
        color: #1b1b1b;
        flex: none;
        flex-grow: 1;
        font-family: NexaWebRegular;
        font-size: 18px;
        font-weight: 400;
        height: 25px;
        margin-top: 10px;
        line-height: 140%;
        order: 0;
        width: 100%;
    }
}

@media (max-width: 1440px) {
	ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc {
        border-radius: 2px;
        display: block;
        flex: none;
        flex-grow: 0;
        height: 362px;
        order: 1;
        padding: 8px 0;
        text-align: left;
        width: 100%;
        z-index: 1;
    }
	
    ul.search-results.desc li.search-result-item.desc {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 80px !important;
        max-width: 100%;
        padding: 0;
        width: 24.4444449%;
    }
	    ul.search-results.desc li.search-result-item.desc a.product__link .product-details.desc .product__image {
        height: 330px;
        width: 100%;
    }
    ul.search-results.desc {
        align-content: flex-start;
        align-items: center;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        height: 404px;
        justify-content: flex-start;
        margin: 0 auto 24px;
        overflow: scroll;
        position: relative;
        top: 0;
        width: 100%;
    }
	    ul.search-results.desc li.search-result-item.desc .product__image>img {
        height: 100%;
        min-height: 288px;
        object-fit: cover !important;
    }
	    li.search-result-item.desc a.product__link .product-details.desc .product__name {
        font-feature-settings: "pnum" on, "lnum" on;
        color: #1b1b1b;
        flex: none;
        flex-grow: 1;
        font-family: NexaWebRegular;
        font-size: 18px;
        font-weight: 400;
        height: 25px;
        margin-top: 10px;
        line-height: 140%;
        order: 0;
        width: 100%;
    }
}
@media (max-width: 1366px) {
	
}
@media (max-width: 1228.8px) {

}
@media (max-width: 1024px) {
a.product__link {
          max-width: 100%;
        margin: 0;
}

	    .product-details p, input[type=email], input[type=number], input[type=radio], input[type=tel], input[type=text] {
        margin: 0px 0 !important;
    }
}