.loading-placeholder {
    width: 100%;
    height: 200px; /* Высота изображения */
    background-color: #f8f8f8; /* Цвет фона */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #888;
    border-radius: 8px;
}
.product__image {
    background: #fff;
}


@media (min-width: 820px) and (max-width: 1180px) {
	
	.category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 670px !important;
    }
}
@media (max-width: 1920px) {
.product__card__card .product__link .product__image {
        display: block;
        margin-bottom: 12px;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 100%;
        min-height: 499px;
    }
	
    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 496px;
    }
    .product__image>img {
     height: auto;
    width: 100%;
    position: relative;
    text-align: center;
    }
    .product__card__detail {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
    }
    .product__name {
        font-family: 'NexaWebRegular';
        font-size: 18px;
        font-weight: 400;
        width: 100%;
        height: 25px;
        line-height: 140%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #1B1B1B;
        flex: none;
        order: 0;
        flex-grow: 1;
    }
    .product__description {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1px;
        text-transform: lowercase;
        text-align: center;
    }
    .product__price {
        align-items: center;
        display: flex;
        font-family: 'NexaWebRegular';
        justify-content: flex-start;
        width:100%;
        height: 25px;
        font-weight: 400;
        line-height: 140%;
        text-transform: lowercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #1B1B1B;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
}

span.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.MuiBadge-colorError.BaseBadge-badge.css-1fc3w67 {
    background-color: #10059f;
}


svg.addtowishlist:hover path {
    fill: #10059f;
    color: #10059f;
}

svg.addtowishlist:hover {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

svg.addtowishlist.active {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}


.wish__remove__icon svg.addtowishlist:hover {
    background-image: none;
}

svg.addtowishlist.active path {
    fill: #10059f;
}

svg.addtocart:hover path {
    fill: #10059f;
    color: #10059f;
}

.css-1n1ln7:hover {
    background-color: rgb(0 0 0 / 0%) !important;
}

.wish__remove__icon svg.addtowishlist path,
svg.addtowishlist.active path,
svg.addtowishlist path:hover {
    fill: #10059f;
}

.cartItems {
    border-top: 1px solid #ddd;
}

@media (max-width: 1707px) {
    .product__price {
        font-family: 'NexaWebRegular';
        width: 100% !important;
        height: 25px !important;
        font-size: 16px !important;
    }
}

@media (max-width: 1536px) {
	.product__card__card .product__link .product__image {
        display: block;
        margin-bottom: 12px;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-box-align: center;
        -moz-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 100%;
        min-height: 370px;
    }
    .product__price {
        font-family: 'NexaWebRegular';
        width: 100% !important;
        height: 25px !important;
        font-size: 16px !important;
    }
	
	    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 372px;
    }
}

@media (max-width: 1440px) {
    .product__price {
        font-family: 'NexaWebRegular';
        width: 100%!important;
        height: 20px !important;
        font-size: 16px !important;
    }
	    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 340px !important;
    }
.product__image {
        display: block;
        margin-bottom: 10px !important;
        width: 100%;
    }
.product__card__card .product__link .product__image {
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        display: block;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        min-height:338px;
        width: 100%;
    }
}
@media (min-width: 290px) and (max-width: 440px) {
	
.product__card__action svg.addtowishlist:hover.active {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 2.022C9.879.755 11.373 0 13.09 0a4.98 4.98 0 013.47 1.404A4.732 4.732 0 0118 4.787c0 2.33-1.49 4.753-4.426 7.204a27.408 27.408 0 01-4.342 2.952.501.501 0 01-.464 0 27.41 27.41 0 01-4.342-2.952C1.49 9.541 0 7.116 0 4.787a4.732 4.732 0 011.44-3.383A4.98 4.98 0 014.91 0C6.626 0 8.12.755 9 2.022z' fill='%2310059F'/%3E%3C/svg%3E") !important;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.product__card__action svg.addtowishlist {
    /* Тут можно установить базовые стили для иконки */
    transition: background-image 0.3s ease; /* Добавляем переход для плавного изменения */
}

.product__card__action svg.addtowishlist:hover {
    background-image: none; /* Убираем фоновое изображение при наведении */
}
    .product__image>img {
        transition: 1s;
        margin: 0 auto;
        width: 100% !important;
        border: none;
        object-fit: contain;
    }    
    .cartItems {
        border-top: 0px solid #ddd;
    }
    .product__image {
        width: 100%;
        transition: 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3px !important;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        min-height: 248px !important;
    }
    .product__name {
        height: 19px;
        max-height: 20px;
        font-size: 14px;
        font-family: 'NexaWebRegular';
        width: 100% !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: lowercase;
        font-weight: 400;
    }
	.shop__category__product__card .product__card__card .product__link .product__image, .category__product__card .product__card__card .product__link .product__image{
		min-height:234px !important;
	}
}
.catalog-card__description {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
}

@media (min-width: 1023px) and (max-width: 1366px) {
	
     .product__card__card .product__link .product__image {
        min-height: 680px;
    }
	    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 680px !important;
    }
}

@media (min-width: 820px) and (max-width: 1023px) {
.product__card__card .product__link .product__image {
        min-height: 536px;
    }
	    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 540px !important;
    }
}
@media (min-width: 430px) and (max-width: 744px) {
    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 240px !important;
    }
	    .product__card__card .product__link .product__image {
        min-height: 240px;
    }
    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 240px !important;
    }
	    .featured__products .product__card__card .product__link .product__image {
        min-height: 480px;
    }
	.new__products .product__card__card .product__link .product__image {
        min-height: 480px;
    }
}
@media (min-width: 745px) and (max-width: 768px) {
    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 456px !important;
    }
	    .product__card__card .product__link .product__image {
        min-height: 500px;
    }
	    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 540px !important;
    }
}

.out-of-stock {
    opacity: 0.5;
    pointer-events: none; /* Отключает клики */
}

.out-of-stock-text {
    color: #818181; /* Красный цвет текста */
    font-weight: bold;
}
@media (min-width: 100px) and (max-width: 320px) {
    .category__product__card .product__card__card .product__link .product__image, .shop__category__product__card .product__card__card .product__link .product__image {
        min-height: 185px !important;
    }
}