@media (min-width:290px) and (max-width:1024px) {
	
.cart-container.MuiBox-root.css-1ir8y05 {
    width: 100%;
}
	
	.shop__cart__items {
    height: auto;
    margin-bottom: 115px !important;
    width: 100%;
}
.MuiModal-root .cart-container.MuiBox-root {
    margin: 0;
    padding: 0 20px;
}
.MuiModal-root .cart__header {
        display: block;
        position: relative;
        right: 0;
        top: 24px;
        z-index: 999;
    }
	.Checkout__container {
    margin: 24px 0 0px !important;
    padding: 0;
}
}
.cart__items__container {
    width: 100%;
    min-width: 200px;
    min-height: 100px;
    height: 85%;
    overflow: auto;
    margin: 40px 0 0;
    display: flex;
    flex-direction: column;
}
.cart__header h2 {
    width: 97px;
    height: 30px;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cartItems{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
}

.cart__header {
    width: 100%;
    height: 30px;
    text-align: left;
}

.shop__cart__items {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
}

.cart__empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    font-family: NexaWebRegular;
    line-height: 125%;
    text-align: center;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
	margin: 104px 0 0;
}
a.return {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    grid-gap: 10px;
    gap: 10px;
    height: 48px;
    background: #1b1b1b;
    order: 2;
    align-content: center;
    margin: 40px auto;
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.name-composition, .name-quantity, .name-size {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    font-family: NexaWebRegular;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #bbb;
    flex: none;
    order: 2;
    flex-grow: 0;
    padding: 0 16px 0 0;
}
a.return:hover {
    background: #10059f;
}

.options {
    height: 140px;
    width: 100%;
    z-index: 9999;
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 91%;
}
.total__amount {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    bottom: 0;
    align-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin: 24px 0 24px 0;
    right: 1px;
    position: relative;
}
.cart__item__info div {
    margin: 8px 0;
}
.total__amount__label, .total__amount__value {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
	font-family: NexaWebRegular;
    flex-wrap: nowrap;
    flex-direction: row;
}

.checkout {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
    gap: 10px;
    height: 48px;
    background: #1B1B1B;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    align-content: center;
}
.cart__header h2 {
    display: flex;
    height: 45px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: NexaWebRegular;
    text-transform: lowercase;
    margin: 0;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    border: none;
    width: 100%;
    font-family: NexaWebRegular;
    margin: 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -.02em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #fff;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0;
    margin: 12px 0 0;
    z-index: 9;
}