
@media (min-width:290px) and (max-width:900px) {
.Stores__text h2 {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}
p.star {
    color: #bbb;
    font-size: 12px;
}
.Stores__text p {
    margin: 0;
}
.Stores__container {
	height: auto;
    width: 100%;
    padding: 0 16px;
}
.Stores__content {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.Stores__item {
    width: 100%;
	display:block !important;
    margin: 0px 0 40px auto !important;
}
.Stores__text {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Stores__text p {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.Stores__map {
    flex: 1;
}

.Stores__container nav.breadcrumbs {
    display: none;
}
.Stores__item .Stores__text {
    width: 100% !important;
    padding-left: 0% !important;
	margin: 0px 0 24px 0;
}
.Stores__text p {
    margin: 0 auto;
}
.Stores__container {
    height: auto;
    width: 100%;
    padding: 0 16px !important;
}
}
.Stores__container {
    height: auto;
    width: 100%;
    padding: 0 200px;
}
.Stores {
    min-height: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    align-content: flex-start;
}

.Stores__container {
	height: auto;
    width: 100%;
}
nav.breadcrumbs {
    margin: 0 0 40px 0;
}
.Stores__content {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.Stores__item {
    display: flex;
    width: 100%;
    margin: 40px 0 40px auto;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Stores__item .Stores__text {
    width: 50%;
    padding-left: 8%;
}
.Stores__text {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 125%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Stores__text p {
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.Stores__map {
    flex: 1;
}
.Stores__text p {
    margin: 0;
}
p.star {
    color: #646464;
    font-size: 12px;
}