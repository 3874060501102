@media (min-width: 290px) and (max-width: 1024px) {
	.category__header__big {
    display: none !important;
}
	.product__name {
    font-size: 16px;
}
.product__link {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: flex-start;
	margin-bottom: 24px;
}
.show__filter .MuiBox-root {
    width: 100% !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth {
    width: 166px !important;
}
.shop__category__container {
    padding: 0!important;
}
.shop__category__head h2 {
    font-size: 24px !important;
}
.shop__category__container {
    margin-top: 24px !important;
}
    .shop__category__product__card .product__card__card .product__card .product__card__detail .product__card__action {
        top: -443px !important;
        right: 0 !important;
    }
.shop__category__product__card {
        grid-gap:11px !important;
        gap: 11px !important;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start !important;
}ul.MuiList-root.MuiList-padding.MuiMenu-list {
    float: right;
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 8px 0;
    position: relative;
    width: 180px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
    width: 100%;
    height: 22px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.shop__category__product__card .product__card__card {
        margin: 0;
        width: 100% !important;
    }
.shop__category__product__card .product__card__card .product__card .product__image>img {
    max-width: 100%;
    transition: 1s;
    margin: 0 auto;
    border: none;
}
 .search-results {
        grid-gap: 11px;
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0;
        justify-content: space-between;
        list-style: none;
        margin: 24px auto;
        padding: 0;
        width: 100%;
}
.cart__item__card {
        grid-gap: 11px;
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0;
        justify-content: space-between;
        list-style: none;
        margin: 24px auto;
        padding: 0;
        width: 100%;
    }
ul.search-results li.search-result-item .product-image {
        margin: 0 auto;
        max-width: 100%;
        object-fit: contain;
        width: 100%;
}
.shop__category__container, .shop__category__header {
    top: 0px;
    position: relative;
}
}
span.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb {
    color: #10059f !important;
    border-radius: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
}
.shop__category__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 100%;
    align-content: center;
    flex-wrap: nowrap;
}
.shop__category__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px auto;
}
.shop__category__header__big {
    height: auto;
    margin: 0px 0;
}

.shop__category__product__card .product__card__card {
    height: auto;
    width: 16.4888889%!important;
    margin: 0;
}
.shop__category__card__container, .shop__category__product__card {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.shop__category__product__card {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
	    gap: 24px;
    width: 100%;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.shop__category__product__card .product__card__card .product__card .product__card__detail .product__card__action {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -338px;
    right: 9px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 5px;
    align-content: center;
    align-items: center;
}
.shop__category__head h2 {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.01em;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
}
.filters {
    height: 0;
    transition: height 0.3s ease-out;
}

.filters--open {
    height: auto; /* allows the element to expand to its full height */
}
.shop__container {
    padding: 0 200px;
    min-height: 100vh;
}

@media (max-width: 375px) {
    .shop__category__product__card .product__card__card {
        margin: 0;
        width: 100% !important;
    }
	.MuiDialogContent-root .filter-section {
        width: auto;
        margin: 0 auto !important;
    }
}

@media (max-width: 1920px) {
	
.shop__category__card__container, .shop__category__product__card {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
}
.shop__category__product__card {
    display: inline-flex;
    gap: 24px;
    height: auto;
    justify-content: flex-start;
    width: 100%;
}
.shop__category__product__card .product__card__card .product__link .product__image {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 512.75px !important;
	margin-bottom: 10px !important;
}
    .image-indicators {
        display: none;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 1536px) {  
 .shop__category__product__card {
        display: inline-flex !important;
        gap: 5px !important;
        height: auto !important;
        justify-content: flex-start !important;
        width: 100% !important;
    }
.category__product__card .product__card__card .product__card .product__link .image-indicators, .shop__category__product__card .product__card__card .product__card .product__link .image-indicators {
        bottom: 10px;
        margin: 0 auto;
        display: none;
        position: absolute;
        width: 100%;
    }
.shop__category__product__card .product__card__card .product__link .product__image {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 384.75px !important;
	margin-bottom: 10px !important;
}
}
@media (max-width: 1440px) {   
 .shop__category__product__card {
        display: inline-flex !important;
        gap: 6px !important;
        height: auto !important;
        justify-content: flex-start !important;
        width: 100% !important;
    }
.shop__category__product__card .product__card__card .product__link .product__image {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 351.75px !important;
	margin-bottom: 10px !important;
}
.image-indicators {
display: none;	
}
}
@media (max-width: 440px) {
    .shop__category__product__card .product__card__card {
        margin: 0;
                width: 50% !important;
    }
	.MuiDialogContent-root .filter-section {
        width: auto;
        margin: 0 auto !important;
    }
	button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1fbxo {
    right: 14px;
}
.category__product__card .product__card__card .product__card .product__link .image-indicators, .shop__category__product__card .product__card__card .product__card .product__link .image-indicators {
    align-content: center;
    align-items: center;
    bottom: 20px;
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    width: 100%;
}
.image-indicators {
display: flex !important;	
}
}
.shop__category__product__card .product__card__card .product__card .product__link .image-indicators span.dot, .category__product__card .product__card__card .product__card .product__link .image-indicators span.dot  {
    width: 0px;
    height: 0px;
    display: block;
    opacity: 1;
    background: #DFDFDF;
    border-radius: 100%;
    margin: 0 2px;
	transition: background-color 0.3s ease;
}
.shop__category__product__card .product__card__card .product__card .product__link .image-indicators span.dot.active,  .category__product__card .product__card__card .product__card .product__link .image-indicators span.dot.active{
    background: #10059F;    
	width: 0px;
    height: 0px;
}

.product__card__card .product__link .product__image:hover {
	opacity:.9;
}
.product__card__card .product__link .product__image {
    position: relative;
    width: 100%;
    overflow: hidden;    
	cursor: pointer;
}


.product__card__card .product__link .product__image .product__img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; 
    transition: opacity 500ms ease-in-out;
}
.product__card__card .product__link .product__image .product__img.active {
    opacity: 1;
}

.product__card__card .product__link .product__image .product__img.left {
    opacity: 0;
}

.product__card__card .product__link .product__image .product__img.right {
    opacity: 0;
}

.featured__products__container .featured__products .product__card__card .product__link .product__image {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.new__products__container .new__products .product__card__card .product__link .product__image {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.new__products__container .new__products .product__card__card .product__link .product__image .product__img.active {
    opacity: 1;
}

@media (min-width: 430px) and (max-width: 1100px) {
	
	.shop__container {
    min-height: 55vh;
    padding: 0 16px;
}
.featured__products__container .featured__products .product__card__card .product__link .product__image {
    overflow: hidden;
    position: relative;
    width: 99%;
}
.new__products__container .new__products .product__card__card .product__link .product__image {
    overflow: hidden;
    position: relative;
    width: 99%;
}    
.cart__item__card, .search-results {
        gap: 9px;
}    
}    